export const MENU_ITEMS = [
  { name: 'Strona główna', url: '/' },
  {
    name: 'Usługi',
    url: '',
    submenu: [
      { name: 'Kampanie social media', url: '/kampanie-social-media' },
      { name: 'Szkolenia dla firm', url: '/szkolenia-dla-firm' },
      { name: 'Bezpłatny audyt', url: '/bezplatny-audyt' },
      { name: 'Konsultacje indywidualne', url: '/konsultacje' },
      { name: 'Tworzenie stron i integracja Pikseli', url: '/strony-www' },
    ],
  },
  { name: 'O nas', url: '/o-nas' },
  { name: 'Blog', url: 'https://blog.lukaszsokolowski.com', active: true },
  { name: 'Kontakt', url: '/kontakt', class: 'mobile-contact' },
];
