import useMediaQuery from './useMediaQuery';

const useMedia = () => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const isTablet = useMediaQuery('(max-width: 967px) and (max-width: 959px)');
  const isSmallDesktop = useMediaQuery(
    '(min-width: 768px) and (max-width: 1024px)',
  );
  const isNotDesktop = useMediaQuery('(max-width: 1199px)');
  const isDesktop = useMediaQuery('(min-width: 1200px)');

  return {
    isMobile,
    isTablet,
    isSmallDesktop,
    isNotDesktop,
    isDesktop,
  };
};

export default useMedia;
