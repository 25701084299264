export const validateFirstName = (firstName: string) => {
  if (!firstName.trim()) {
    return 'Pole Imię jest wymagane.';
  } else if (firstName.length < 2) {
    return 'Imię musi składać się z minimum dwóch znaków.';
  }
  return '';
};

export const validateLastName = (lastName: string) => {
  if (!lastName.trim()) {
    return 'Pole Nazwisko jest wymagane.';
  } else if (lastName.length < 2) {
    return 'Nazwisko musi składać się z minimum dwóch znaków.';
  }
  return '';
};

export const validateEmail = (email: string) => {
  if (!email.trim()) {
    return 'Pole Email jest wymagane.';
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    return 'Wprowadź prawidłowy adres e-mail.';
  }
  return '';
};

export const validateMessage = (message: string) => {
  if (!message.trim()) {
    return 'Pole Wiadomość jest wymagane.';
  }
  return '';
};
