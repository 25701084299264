import EnvelopeIcon from "assets/icons/envelope.svg?react";
import FacebookIcon from "assets/icons/facebook.svg?react";
import LinkedInIcon from "assets/icons/linkedin.svg?react";
import LocationIcon from "assets/icons/location.svg?react";
import PhoneIcon from "assets/icons/phone.svg?react";
import TikTokIcon from "assets/icons/tiktok.svg?react";
import YoutubeIcon from "assets/icons/youtube.svg?react";

export const FOOTER_DATA = {
	contact: {
		email: {
			address: "kontakt@lukaszsokolowski.com",
			icon: EnvelopeIcon,
		},
		phone: {
			number: "+48 728 465 019",
			icon: PhoneIcon,
		},
		location: {
			address: "Warszawa i cała Polska",
			icon: LocationIcon,
		},
	},
	links: {
		services: {
			header: "Usługi",
			items: [
				// { title: 'Kursy', url: '/kursy' },
				{ title: "Szkolenia", url: "/szkolenia-dla-firm" },
				{ title: "Prowadzenie kampanii", url: "/kampanie-social-media" },
				{ title: "Konsultacje indywidualne", url: "/konsultacje" },
			],
		},
		learnMore: {
			header: "Dowiedz się więcej",
			items: [
				{ title: "O nas", url: "/o-nas" },
				{ title: "Blog", url: "https://blog.lukaszsokolowski.com" },
				{ title: "Kontakt", url: "/kontakt" },
			],
		},
		socialMedia: {
			header: "Social Media",
			items: [
				{
					name: "TikTok",
					url: "https://www.tiktok.com/@sokolowskimarketing",
					icon: TikTokIcon,
				},
				{
					name: "Facebook",
					url: "https://www.facebook.com/luksokolowskimarketing",
					icon: FacebookIcon,
				},
				{
					name: "Youtube",
					url: "https://www.youtube.com/@sokolowskimarketing",
					icon: YoutubeIcon,
				},
				{
					name: "LinkedIn",
					url: "https://www.linkedin.com/in/sokolowskilukasz/",
					icon: LinkedInIcon,
				},
			],
		},
	},
};
