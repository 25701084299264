import { useRef } from 'react';

import { BRANDS_DATA } from 'components/HomePage/HomePage.constants';
import useMedia from 'hooks/useMedia';
import SwiperCore from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './Brands.module.css';

const Brands = () => {
  const { isMobile, isSmallDesktop } = useMedia();

  const swiperRef = useRef<SwiperCore>();

  const displaySlides = () => {
    if (isMobile) return 1;
    if (isSmallDesktop) return 3;
    return 7;
  };

  return (
    <div className={styles.brandsWrapper}>
      <Swiper
        modules={[Autoplay, Pagination, Navigation, Scrollbar]}
        spaceBetween={50}
        slidesPerView={displaySlides()}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        autoplay={{
          delay: 1,
          disableOnInteraction: true,
        }}
        speed={3000}
        effect="slide"
        direction={'horizontal'}
        loop={true}
        freeMode={true}
      >
        {BRANDS_DATA.map((brand, index) => (
          <SwiperSlide key={`${brand.alt}-${index}`}>
            <brand.image title={brand.alt} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Brands;
