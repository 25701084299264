import { sanitize } from 'isomorphic-dompurify';

import styles from './Headline.module.css';

type HeadingSize = 'one' | 'two' | 'three' | 'four' | 'five';
type HeadingTagsType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface HeadlineProps {
  size?: HeadingSize;
  as: HeadingTagsType;
  colors: string;
  center?: boolean;
  text: string;
  spanColor?: string;
  moreSpace?: boolean;
  className?: string;
}

const Headline = ({
  size = 'one',
  as: Tag = 'h2',
  colors,
  center,
  text,
  spanColor,
  moreSpace,
  className = '',
}: HeadlineProps) => {
  const combinedClassNames = `${styles.headline} ${styles[size]} ${
    moreSpace ? styles.moreSpace : ''
  } ${center ? styles.center : ''} ${className}`;

  const styleOverrides = {
    color: colors,
    '--span-color': spanColor || 'inherit',
  };

  return (
    <Tag
      className={combinedClassNames}
      style={styleOverrides}
      dangerouslySetInnerHTML={{ __html: sanitize(text) }}
    />
  );
};

export default Headline;
