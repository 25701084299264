import AchievementIcon from 'assets/icons/achievement.svg?react';
import LayersIcon from 'assets/icons/layers.svg?react';
import RocketIcon from 'assets/icons/rocket.svg?react';
import UsersIcon from 'assets/icons/users.svg?react';

import EcocarLogo from './assets/ecocar.svg?react';
import OnLogo from './assets/on.svg?react';
import PrimaLogo from './assets/prima.svg?react';
import TolpaLogo from './assets/tolpa.svg?react';
import ViessmannLogo from './assets/viessmann.svg?react';
import VolvoLogo from './assets/volvo.svg?react';
import WroclawLogo from './assets/wroc.svg?react';

export const IDEA_COPY = 'Poznaj pomysł na:';
export const HEADLINE_COPY = 'Skuteczny marketing dla firm!';
export const SUBHEADLINE_COPY =
  'Nazywam się Łukasz Sokołowski i prowadzę agencję marketingową Purple. W naszej agencji marketingowej, specjalizujemy się w Social Media. Tworzymy reklamy Meta Ads i TikTok Ads, opracowujemy strategie biznesowe dla firm pragnących pozyskiwać nowych klientów. Oferujemy szkolenia, konsultacje oraz doradztwo dla organizacji szukających wiedzy eksperckiej z obszaru online marketingu.';

export const BTN_CTA_PRIMARY = 'Napisz do nas';
export const BTN_CTA_SECONDARY = 'Dowiedz się więcej';

export const TITLE_WHAT_I_DO = 'Co możemy dla Ciebie zrobić?';
export const SUBTITLE_WHAT_I_DO =
  'Zobacz, jakie usługi oferujemy i jak możemy pomóc Twojej firmie.';

export const TITLE_BRANDS = 'Marki, dla których pracowaliśmy';
export const SUBTITLE_BRANDS = 'Zobacz, jakie marki zaufały naszej wiedzy.';
export const FOOTER_BRANDS =
  'oraz ponad 50 innych firm. Teraz możemy pracować dla Ciebie.';

export const WHY_ME_CTA = 'Dlaczego Purple?';
export const WHY_ME_HEADLINE = 'Pomożemy Twojej marce w świecie cyfrowym';
export const WHY_ME_SUBHEADLINE =
  'Pomożemy rozwinąć Twój biznes, oferując jakość i zaangażowanie. W naszej agencji Purple specjalizujemy się w kampaniach reklamowych opartych na systemach TikTok Ads i Meta Ads, gdzie kładziemy duży nacisk na połączenie kreacji z mediami. <br /><br />Planujemy i przeprowadzamy efektywne działania reklamowe, doradzamy w zakresie strategii oraz poprawy efektywności kampanii w Social Media. Naszym celem jest nie tylko zwiększenie widoczności marki w mediach społecznościowych, ale przede wszystkim osiągnięcie realnych efektów biznesowych. Aby osiągać dobre wyniki, zrozumiemy Twój biznes, poświęcimy mu czas i zaangażujemy się w rozwój Twojej firmy.';

export const TITLE_TESTIMONIALS = 'Co mówią nasi klienci';
export const SUBTITLE_TESTIMONIALS = 'Opinie na temat naszej pracy.';

export const TITLE_BLOG = 'Dobre artykuły marketingowe';
export const SUBTITLE_BLOG =
  'Rozwiń swoją wiedzę, dzięki darmowym poradnikom marketingowym.';

export const HEADLINE_BANNER =
  'Pracuj z nami, a zaoszczędzisz czas i pozyskasz lepszych klientów';
export const CTA_BANNER = 'Porozmawiajmy';

export const TITLE_NEWSLETTER = 'Dołącz do newslettera';
export const SUBTITLE_NEWSLETTER =
  'Raz na jakiś czas wysyłamy wartościowe materiały na temat marketingu online.';

export const BANNER_INFO_HEADLINE = `Zaplanuj 30-minutową, <span>bezpłatną</span> konsultację, aby omówić możliwości Twojego biznesu.`;
export const BANNER_INFO_SUBHEADLINE = `Dowiesz się jak Twoja firma może się rozwinąć w świecie komunikacji Social Media `;
export const BANNER_INFO_CTA = 'Umów się';

export const BOXES_DATA = [
  { number: '400<span>+</span>', text: 'zrealizowanych kampanii' },
  { number: '65<span>+</span>', text: 'obsłużonych klientów' },
  { number: '13<span>+</span>', text: 'lat doświadczenia w marketingu' },
];

export const OFFERS_DATA = [
  {
    title: 'Konsultacje indywidualne',
    btn_cta: 'Umów sie na konsultację',
    url: '/konsultacje',
  },
  {
    title: 'Szkolenia dla <br /> firm',
    btn_cta: 'Sprawdź ofertę',
    url: '/szkolenia-dla-firm',
  },
  {
    title: 'Bezpłatny <br />audyt',
    btn_cta: 'Poznaj szczegóły',
    url: '/bezplatny-audyt',
  },
  {
    title: 'Kampanie w Social Media',
    btn_cta: 'Poznaj szczegóły',
    url: '/kampanie-social-media',
  },
];

export const BRANDS_DATA = [
  { image: EcocarLogo, alt: 'EcoCar' },
  { image: OnLogo, alt: 'OptimumNutrition' },
  { image: PrimaLogo, alt: 'Prima' },
  { image: TolpaLogo, alt: 'Tolpa' },
  { image: ViessmannLogo, alt: 'Viesmann' },
  { image: VolvoLogo, alt: 'Volvo' },
  { image: WroclawLogo, alt: 'Wrocław Miasto' },
  { image: EcocarLogo, alt: 'EcoCar' },
  { image: OnLogo, alt: 'OptimumNutrition' },
  { image: PrimaLogo, alt: 'Prima' },
  { image: TolpaLogo, alt: 'Tolpa' },
  { image: ViessmannLogo, alt: 'Viesmann' },
  { image: VolvoLogo, alt: 'Volvo' },
  { image: WroclawLogo, alt: 'Wrocław Miasto' },
];

export const ABOUT_ME_DATA = [
  { number: '55<span>+</span>', text: 'Skończonych projektów' },
  { number: '20<span>+</span>', text: 'Sprawdź ofertę' },
  { number: '13<span>+</span>', text: 'Lat doświadczenia' },
];

export const TESTIMONIALS_DATA = [
  {
    name: 'Wiola Piasecka',
    company: 'Viessmann Polska',
    text: 'Łukasz to nie tylko doświadczony specjalista w obszarze działań w mediach społecznościowych, ale także kreatywny przedsiębiorca gotowy wziąć na siebie wyzwania. Jego podejście łączy w sobie strategiczne podejście do kampanii płatnych z innowacyjnymi kreacjami wideo, co sprawia, że jest idealnym partnerem dla firm chcących osiągnąć sukces online.',
    title: 'Doświadczony specjalista od social media',
  },
  {
    name: 'Rafał Zakrzewski',
    company: 'SFD',
    text: 'Miałem okazję współpracować z Łukaszem przy kilku projektach. Jest nie tylko ekspertem w dziedzinie Social Media ale dysponuje również uniwersalną szeroką wiedzą biznesową która pomaga mu skutecznie odnaleźć się w dowolnej branży. Poza za tym, co w świecie marketingu nie jest regułą, Łukasz jest doskonale zorganizowany a to w kontekście projektów które realizujemy jest to bardzo ważne -:)',
    title: 'Ekspert social media i uniwersalny specjalista biznesowy',
  },
  {
    name: 'Aleksandra Kolasiewicz',
    company: 'Mówię Podmiotowo',
    text: 'Polecam z całego serca współpracę z Łukaszem. Posiada on bowiem nie tylko zmysł analityka, ale także bacznego obserwatora zmieniającej się rzeczywistości. W SM czuje się jak ryba w wodzie- to widać nie tylko we współpracy sensu stricte, ale także w jego wiedzy. Bardzo polecam!',
    title: 'Analityczny umysł i ekspert social media',
  },
  {
    name: 'Ewa Wdowska',
    company: 'Suntago',
    text: 'Wspólnie z Łukaszem zaplanowaliśmy działania digitalowe dla marki Suntago na lata 2022-2024. Łukasz zapewnił nam nieocenione wsparcie zarówno w planowaniu strategicznym, jak i bieżącej optymalizacji działań digitalowych. Polecam współpracę z Łukaszem, aby odkryć jego ogromną wiedzę i pasję do digitalu. Wszystkiego najlepszego Łukasz ! 🙌🏻',
    title: 'Nieocenione wsparcie w planowaniu i optymalizacji',
  },
  {
    name: 'Agata Papis-Maniecka',
    company: 'Facetka od reklamy',
    text: 'Z Łukaszem pracuje się bardzo dobrze. Miał okazje planować moje kampanie na Meta i TikToku, wyniki przeszły moje oczekiwania. Sam wychodzi z inicjatywą i pomaga zoptymalizować kampanie, żeby wyniki były jeszcze lepsze. Polecam z całego serca ',
    title: 'Skuteczny strateg kampanii na Meta i TikToku',
  },
  {
    name: 'Monika Żurek',
    company: 'Warso Nieruchomości',
    text: 'Polecam współpracę z Łukaszem. Sprawnie pracuje w obszarze marketingu i mediów społecznościowych. Polecam',
    title: 'Sprawny specjalista marketingu i social media',
  },
  {
    name: 'Grzegorz Gierejko',
    company: 'Lodziarnia Alepjska',
    text: 'Super obsługa, profesjonalne podejście i znajomość tematu. Bardzo rekomenduję pana Łukasza.',
    title: 'Obsługa i profesjonalne podejście',
  },
  {
    name: 'Witek Wysocki',
    company: 'Nest Bank',
    text: 'Łukasz zawsze wykazuje się niezwykłym profesjonalizmem oraz pasją do swojej pracy. Jego twóre myślenie oraz często nieszablonowe podejście, przekłada się na skuteczne rozwiązania i tym samym lepsze efekty kampanii performance marketingu.',
    title: 'Profesjonalista z pasją do marketingu',
  },
  {
    name: 'Kaja Mianowana',
    company: 'Aktorka Teatru Roma',
    text: 'Bardzo polecam Łukasza! Super kontakt i indywidualne podejście do potrzeb konkretnej kampanii. Dziękuję za współpracę!',
    title: 'Super kontakt i indywidualne podejście',
  },
  {
    name: 'Mateusz Kawalec',
    company: 'FizjoTrener',
    text: 'Polecam współpracę z Łukaszem. Pełen profesjonalizm i osiągnięcie zamierzonego celu',
    title: 'Ekspert w marketingu i mediach społecznościowych',
  },
  {
    name: 'Maksymilian Witkowski',
    company: 'CEO Berryagency',
    text: 'Chciałbym zarekomendować Łukasza jako szkoleniowca w zakresie TikTok Ads. Łukasz podjął się wyzwania wyszkolenia zespołu marketingowego lidera w branży ubezpieczeniowej z Warszawy i podał mnóstwo przykładów branżowych i okołobranżowych, które były doskonałą inspiracją. Dostałem pozytywny feedback od klienta. Serdecznie polecam.',
    title: 'Skuteczny szkoleniowiec TikTok Ads',
  },
  {
    name: 'Janusz Krzeszowski',
    company: 'Wroclaw.pl',
    text: 'Otwarty, elastyczny i dobrze przygotowany. Łukasz w czasie szkolenia prezentuje nie tylko teoretyczne zagadnienia związane z formatami wideo w social mediach, lecz także dostarcza wartościowe dane i ciekawe przykłady. Cieszymy się, że mogliśmy wartościowo spędzić wspólny czas.',
    title: 'Otwarty, elastyczny i dobrze przygotowany szkoleniowiec',
  },
];

export const BANNER_BOX_DATA = [
  { icon: AchievementIcon, text: 'Sprzedaż produktów' },
  { icon: LayersIcon, text: 'Zasięg i rozpoznawalność' },
  { icon: UsersIcon, text: 'Nowi klienci' },
  { icon: RocketIcon, text: 'Strategia komunikacji' },
];
