import { SpacingInterface } from 'assets/styles/theme.interface';
import Headline from 'components/Moleculars/Headline';
import ImageTextCta from 'components/Moleculars/ImageTextCta';
import Spacing from 'components/Moleculars/Spacing';
import Subheadline from 'components/Moleculars/Subheadline';
import useMedia from 'hooks/useMedia';
import useMyTheme from 'hooks/useMyTheme';
import styled from 'styled-components';

import {
  COURSES_DESCRIPTION,
  COURSES_TITLE,
  KAMPANIA_NA_FACEBOOKU,
} from './CoursesPage.constants';

const HeroSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ theme }) => theme.mq.tablet} {
    flex-direction: column-reverse;
    margin-top: 78px;
  }
`;

const TextWrapper = styled.div<{ spacing: SpacingInterface }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0 80px;
  ${({ theme }) => theme.mq.tablet} {
    padding: 0 ${({ spacing }) => spacing.mega};
    align-items: center;
    text-align: center;
    margin-top: ${({ spacing }) => spacing.tera};
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & img {
    width: 600px;
    height: 415px;
  }

  ${({ theme }) => theme.mq.smallDesktop} {
    & img {
      width: 420px;
      height: 100%;
    }
  }
  ${({ theme }) => theme.mq.tablet} {
    max-width: 100%;
    & img {
      width: 100vw;
      height: 300px;
    }
  }
`;

const CoursesPage = () => {
  const { colors, spacing } = useMyTheme();
  const { isNotDesktop } = useMedia();

  return (
    <>
      <HeroSectionWrapper>
        <TextWrapper spacing={spacing}>
          <Headline
            size={isNotDesktop ? 'three' : 'two'}
            as="h1"
            colors={colors.darkHeader}
            text={COURSES_TITLE}
          />
          <Spacing marginBottomKey="giga" />
          <Subheadline
            as="h2"
            text={COURSES_DESCRIPTION}
            darkSubheader={colors.darkSubheader}
            size={isNotDesktop ? 'two' : 'one'}
          />
          <Spacing marginBottomKey="giga" />
        </TextWrapper>
        <ImageWrapper>
        </ImageWrapper>
      </HeroSectionWrapper>
      <ImageTextCta
        image={KAMPANIA_NA_FACEBOOKU.image}
        textTitle={KAMPANIA_NA_FACEBOOKU.title}
        textDescription={KAMPANIA_NA_FACEBOOKU.description}
        cta={KAMPANIA_NA_FACEBOOKU.cta}
        displayAdditionalTitle={true}
        Icon={KAMPANIA_NA_FACEBOOKU.icon}
        additionalTitle={KAMPANIA_NA_FACEBOOKU.type}
        link={KAMPANIA_NA_FACEBOOKU.link}
      />
    </>
  );
};

export default CoursesPage;
