import { SpacingInterface } from 'assets/styles/theme.interface';
import BoxesWitIconAndText from 'components/Moleculars/BoxesWitIconAndText';
import Container from 'components/Moleculars/Container';
import FormContact from 'components/Moleculars/FormContact';
import FormContactIcons from 'components/Moleculars/FormContactIcons';
import ImageTextCta from 'components/Moleculars/ImageTextCta';
import MainBanner from 'components/Moleculars/MainBanner';
import ServiceCards from 'components/Moleculars/ServiceCards';
import Spacing from 'components/Moleculars/Spacing';
import Title from 'components/Moleculars/Title';
import useMyTheme from 'hooks/useMyTheme';
import { styled } from 'styled-components';

import {
  BANNER_HEADLINE,
  BANNER_SUBHEADLINE,
  SOCIAL_MEDIA_DATA,
  SOCIAL_MEDIA_EXAMPLES,
  SOCIAL_MEDIA_EXAMPLES_FIRST,
  SOCIAL_MEDIA_EXAMPLES_SECOND,
  SOCIAL_MEDIA_EXAMPLES_THIRD,
  SOCIAL_MEDIA_TITLE,
  SOCIAL_MEDIA_TITLE_FORM_CONTACT,
  SOCIAL_MEDIA_TITLE_SECOND,
} from './SocialMediaCampain.constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormWrapper = styled.div<{ spacing: SpacingInterface }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  ${({ theme }) => theme.mq.smallDesktop} {
    padding: ${({ spacing }) => spacing.mega};
  }

  ${({ theme }) => theme.mq.tablet} {
    flex-direction: column;
    padding: ${({ spacing }) => spacing.mega};
  }
`;

const SocialMediaCampain = () => {
  const { spacing } = useMyTheme();

  return (
    <>
      <MainBanner title={BANNER_HEADLINE} description={BANNER_SUBHEADLINE} />
      <Container>
        <Wrapper>
          <Title title={SOCIAL_MEDIA_TITLE} />
          <BoxesWitIconAndText data={SOCIAL_MEDIA_DATA} />
          <Spacing marginBottomKey="zetta" />
          <Title title={SOCIAL_MEDIA_TITLE_SECOND} />
          <ServiceCards services={SOCIAL_MEDIA_EXAMPLES} />
          <Title title={SOCIAL_MEDIA_TITLE_FORM_CONTACT} />
          <FormWrapper spacing={spacing}>
            <FormContact />
            <Spacing marginRightKey="mega" />
            <FormContactIcons />
          </FormWrapper>
          <ImageTextCta
            textTitle={SOCIAL_MEDIA_EXAMPLES_FIRST.title}
            textDescription={SOCIAL_MEDIA_EXAMPLES_FIRST.description}
            image={SOCIAL_MEDIA_EXAMPLES_FIRST.image}
          />
          <ImageTextCta
            textTitle={SOCIAL_MEDIA_EXAMPLES_SECOND.title}
            textDescription={SOCIAL_MEDIA_EXAMPLES_SECOND.description}
            image={SOCIAL_MEDIA_EXAMPLES_SECOND.image}
            reversed
          />
          <ImageTextCta
            textTitle={SOCIAL_MEDIA_EXAMPLES_THIRD.title}
            textDescription={SOCIAL_MEDIA_EXAMPLES_THIRD.description}
            image={SOCIAL_MEDIA_EXAMPLES_THIRD.image}
          />
        </Wrapper>
      </Container>
    </>
  );
};

export default SocialMediaCampain;
