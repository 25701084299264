import computerIcon from 'assets/icons/computer.svg?react';
import dollarSign from 'assets/icons/dollar_sign.svg?react';
import eyeIcon from 'assets/icons/eye.svg?react';
import heartIcon from 'assets/icons/heart.svg?react';
import locationIcon from 'assets/icons/location.svg?react';
import moneyCheckDollar from 'assets/icons/money_check_dollar.svg?react';
import peopleArrows from 'assets/icons/people_arrows.svg?react';
import peopleGroupIcon from 'assets/icons/people_group.svg?react';

export const BANNER_HEADLINE =
  'Profesjonalne tworzenie stron www i integracja pikseli śledzących';
export const BANNER_SUBHEADLINE =
  'Zwiększ swoją widoczność online z naszymi skutecznymi rozwiązaniami webowymi';

export const PAGES_WWW_WHY_DO_YOU_NEED_TITLE =
  'Dlaczego strony internetowe są kluczowe dla Twojej firmy?';
export const PAGES_WWW_WHY_DO_YOU_NEED_DESCRIPTION =
  'W dzisiejszym cyfrowym świecie, strona internetowa to nie tylko wizytówka firmy, ale główny narzędzie w komunikacji z klientem. Zaprojektowane przez nas strony WWW czy strony do zbierania leadów służą nie tylko do prezentacji Twojej marki, ale przede wszystkim do efektywnego przyciągania i konwersji potencjalnych klientów. Nie ma dla nas znaczenia czy dopiero wchodzisz na rynek czy jesteś już ugruntowaną marką – każdy projekt traktujemy indywidualnie, z pasją i zaangażowaniem.';

export const PAGES_WWW_HOW = 'Jak działamy?';
export const PAGES_WWW_HOW_DESCRIPTION = `Nasze podejście do tworzenia stron internetowych opiera się na trzech filarach:
1. Indywidualne podejście do klienta i jego potrzeb
2. Skuteczne rozwiązania technologiczne
3. Optymalizacja pod kątem SEO i konwersji`;

export const PAGES_WWW_WHY_DO_YOU_NEED_DATA = [
  {
    icon: heartIcon,
    title: 'Wzmacniaj swój wizerunek',
    text: 'Budowanie wiarygodności i profesjonalnego wizerunku w branży.',
  },
  {
    icon: computerIcon,
    title: 'Generuj więcej leadów',
    text: 'Efektywne zbieranie danych kontaktowych potencjalnych klientów (leadów), co stanowi podstawę dla skutecznych kampanii marketingowych.',
  },
  {
    icon: locationIcon,
    title: 'Popraw swoje pozycjonowanie',
    text: 'Zwiększanie rozpoznawalności marki dzięki optymalizacji SEO',
  },
];

export const PAGES_WWW_WHY_DO_YOU_NEED_PIXELS_TITLE =
  'Precyzyjne śledzenie działania użytkowników';
export const PAGES_WWW_WHY_DO_YOU_NEED_PIXELS_DESCRIPTION =
  'Zapewnij swojej firmie przewagę dzięki integracji pikseli śledzących na Twojej stronie internetowej. Instalujemy i konfigurujemy piksele dla platform takich jak Meta Ads, TikTok Ads i inne, które pozwalają na śledzenie kluczowych działań użytkowników (takich jak przeglądanie treści, dodanie do koszyka, inicjacja zakupu, złożenie zamówienia i finalizacja płatności). Dzięki temu możesz lepiej zrozumieć swoich klientów i optymalizować swoje kampanie reklamowe, zwiększając ich efektywność i ROI.';
export const PAGES_WWW_WHY_DO_YOU_NEED_PIXELS_DATA = [
  {
    icon: heartIcon,
    title: 'Dokładna analiza zachowań',
    text: 'Zdobądź wgląd w to, jak użytkownicy wchodzą w interakcję z Twoją stroną, co pozwoli na lepsze zrozumienie ich potrzeb i preferencji.',
  },
  {
    icon: computerIcon,
    title: 'Optymalizacja kampanii',
    text: 'Użyj zebranych danych do optymalizacji swoich kampanii reklamowych, co przekłada się na niższe koszty akwizycji i wyższe konwersje.',
  },
  {
    icon: eyeIcon,
    title: 'Wsparcie wieloplatformowe',
    text: 'Bez względu na to, czy Twoja strona działa na WordPressie, Shoperze, czy innej platformie, zapewniamy pełną kompatybilność i wsparcie techniczne.',
  },
];

export const PAGES_WWW_WHY_US = 'Dlaczego warto skorzystać z naszych usług?';
export const PAGES_WHY_US_DATA = [
  {
    name: 'Doświadczenie',
    icon: peopleGroupIcon,
    description:
      'Nasze strony są tworzone przez doświadczonych frontend developerów, którzy mają w swoim portfolio projekty dla znanych marek eCommerce oraz mniejszych specjalistycznych sklepów.',
  },
  {
    name: 'Optymalizacja konwersji',
    icon: dollarSign,
    description:
      'Wszystkie landing page są wyposażone w testy A/B, dzięki czemu możemy dostosować design i treści do potrzeb Twoich klientów, maksymalizując efektywność strony.',
  },
  {
    name: 'Korzyści',
    icon: moneyCheckDollar,
    description:
      'Przy każdym projekcie kładziemy nacisk na indywidualne potrzeby klienta, co przekłada się na wysoką skuteczność w przyciąganiu i utrzymaniu uwagi użytkowników.',
  },
  {
    name: 'Kompleksowa obsługa',
    icon: peopleArrows,
    description:
      'Od koncepcji po wdrożenie i optymalizację – jesteśmy z Tobą na każdym etapie tworzenia strony.',
  },
];

export const PAGES_WWW_CONTACT_FORM_TITLE = 'Zacznijmy od rozmowy';
