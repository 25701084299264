import { SpacingInterface } from 'assets/styles/theme.interface';
import useMyTheme from 'hooks/useMyTheme';
import { styled } from 'styled-components';

import Button from '../Button';
import Headline from '../Headline';
import Spacing from '../Spacing';
import Subheadline from '../Subheadline';

const Wrapper = styled.div<{ spacing: SpacingInterface; reversed?: boolean }>`
  display: flex;
  flex-direction: ${({ reversed }) => (reversed ? 'row-reverse' : 'row')};
  align-items: center;
  gap: ${({ spacing }) => spacing.giga};
  margin: ${({ spacing }) => spacing.exa} auto;
  padding: ${({ spacing }) => spacing.giga};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  min-height: 300px;

  ${({ theme }) => theme.mq.tablet} {
    flex-direction: column;
    justify-content: center;
    padding: ${({ spacing }) => spacing.mega};
  }
`;

const ImageWrapper = styled.div`
  width: 50%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: auto;
    max-width: 100%;
  }

  ${({ theme }) => theme.mq.tablet} {
    width: 100%;
  }
`;

const TextWrapper = styled.div<{ spacing: SpacingInterface }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-left: ${({ spacing }) => spacing.giga};

  ${({ theme }) => theme.mq.tablet} {
    width: 100%;
    margin-left: 0;
    text-align: center;
  }
`;

const AdditionalTextWrapper = styled.div<{ spacing: SpacingInterface }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & svg {
    width: 20px;
    height: 20px;
    margin-right: ${({ spacing }) => spacing.byte};
  }
`;

interface ImageTextCtaInterface {
  image: string;
  textTitle: string;
  textDescription: string;
  cta?: string;
  reversed?: boolean;
  displayAdditionalTitle?: boolean;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  additionalTitle?: string;
  link?: string;
}

const ImageTextCta = ({
  image,
  textTitle,
  textDescription,
  cta,
  reversed = false,
  displayAdditionalTitle = false,
  Icon,
  additionalTitle,
  link,
}: ImageTextCtaInterface) => {
  const { colors, spacing } = useMyTheme();

  return (
    <Wrapper spacing={spacing} reversed={reversed}>
      <ImageWrapper>
        <img src={image} alt={textTitle} />
      </ImageWrapper>
      <TextWrapper spacing={spacing}>
        {displayAdditionalTitle && additionalTitle && Icon && (
          <AdditionalTextWrapper spacing={spacing}>
            <Icon />
            <Headline
              size="four"
              as="h3"
              text={additionalTitle}
              colors={colors.darkHeader}
            />
          </AdditionalTextWrapper>
        )}
        <Headline
          as="h3"
          size="three"
          text={textTitle}
          colors={colors.darkSubheader}
          spanColor={colors.blue}
          moreSpace
        />
        <Spacing marginBottomKey="giga" />
        <Subheadline
          as="h4"
          size="two"
          text={textDescription}
          spanColor={colors.darkSubheader}
        />
        <Spacing marginBottomKey="giga" />
        {cta && (
          <Button
            size="medium"
            label={cta}
            backgroundColor={colors.yellow}
            {...(link && { link: link })}
          />
        )}
      </TextWrapper>
    </Wrapper>
  );
};

export default ImageTextCta;
