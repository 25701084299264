import { create } from 'zustand';

interface NavigationState {
  activeUrl: string | null;
  setActiveUrl: (url: string | null) => void;
  isActiveMobileMenu: boolean;
  setIsActiveMobileMenu: (isActive: boolean) => void;
}

const useNavigationStore = create<NavigationState>((set) => ({
  activeUrl: '/',
  setActiveUrl: (url: string | null) => set({ activeUrl: url }),
  isActiveMobileMenu: false,
  setIsActiveMobileMenu: (isActive: boolean) =>
    set({ isActiveMobileMenu: isActive }),
}));

export default useNavigationStore;
