import isPropValid from '@emotion/is-prop-valid';
import { ColorsInterface } from 'assets/styles/theme.interface';
import useMyTheme from 'hooks/useMyTheme';
import { sanitize } from 'isomorphic-dompurify';
import styled from 'styled-components';

type BodySize = 'one' | 'two' | 'three';
type VariantBodyType = 'highlight' | 'cursive' | undefined;

interface BodyProps {
  size?: BodySize;
  variant?: VariantBodyType;
  darkSubheader?: ColorsInterface['darkSubheader'];
  textColor?: string;
  center?: boolean;
  text: string;
  spanColor?: string;
  icon?: React.ReactNode;
}

const baseStyles = ({
  darkSubheader,
  textColor,
  spanColor,
}: {
  darkSubheader?: ColorsInterface['darkSubheader'];
  textColor?: string;
  spanColor?: string;
}) => `
  color: ${textColor ? textColor : darkSubheader};
  margin: 0;
  white-space: pre-line;

  & span {
    color: ${spanColor};
  }
`;

const sizeStyles = ({ size = 'one' }: BodyProps) => {
  const sizePixels = () => {
    if (size === 'one') {
      return '16px';
    } else if (size === 'two') {
      return '18px';
    } else if (size === 'three') {
      return '12px';
    }
  };
  return `
    font-size: ${sizePixels()};
  `;
};

const variantStyles = ({ variant }: { variant?: VariantBodyType }) => {
  if (!variant) {
    return null;
  } else if (variant === 'highlight') {
    return `font-weight: bold;`;
  } else if (variant === 'cursive') {
    return `font-style: italic;`;
  }
};

const centerStyles = ({ center }: { center?: boolean }) =>
  !center ? null : `text-align: center;`;

const BasicBody = styled('p').withConfig({
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'size',
})<BodyProps>(baseStyles, sizeStyles, variantStyles, centerStyles);

const Body = (props: BodyProps) => {
  const { colors } = useMyTheme();

  return (
    <BasicBody
      {...props}
      {...colors}
      dangerouslySetInnerHTML={{ __html: sanitize(props.text) }}
    />
  );
};

export default Body;
