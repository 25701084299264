import BoxesWitIconAndText from 'components/Moleculars/BoxesWitIconAndText';
import Container from 'components/Moleculars/Container';
import FormContact from 'components/Moleculars/FormContact';
import FormContactIcons from 'components/Moleculars/FormContactIcons';
import Headline from 'components/Moleculars/Headline';
import ImageTextCta from 'components/Moleculars/ImageTextCta';
import MainBanner from 'components/Moleculars/MainBanner';
import Spacing from 'components/Moleculars/Spacing';
import Title from 'components/Moleculars/Title';
import Workshops from 'components/Moleculars/Workshops';
import useMedia from 'hooks/useMedia';
import useMyTheme from 'hooks/useMyTheme';

import {
  BANNER_HEADLINE,
  BANNER_SUBHEADLINE,
  CONSULTATION_BANNER_DATA,
  CONSULTATION_OFFER_DATA,
  CONSULTATION_SUBTITLE_FORM_CONTACT,
  CONSULTATION_TITLE,
  CONSULTATION_TITLE_FORM_CONTACT,
  CONSULTATION_TITLE_SECOND,
  CONSULTATION_TITLE_THIRD,
  CONSULTATION_WORKSHOPS_DATA,
} from './Consultation.constants';
import styles from './Consultation.module.css';

const Consultation = () => {
  const { colors } = useMyTheme();
  const { isMobile } = useMedia();

  return (
    <>
      <MainBanner title={BANNER_HEADLINE} description={BANNER_SUBHEADLINE} />
      <Container>
        <div className={styles.wrapper}>
          <Title title={CONSULTATION_TITLE} />
          <div className={styles.headlineWrapper}>
            <Headline
              text={BANNER_SUBHEADLINE}
              as="h3"
              size={isMobile ? 'five' : 'four'}
              colors={colors.darkHeader}
              center
            />
          </div>
          {!isMobile && <Spacing marginBottomKey="zetta" />}
          <BoxesWitIconAndText data={CONSULTATION_OFFER_DATA} />
          <Title title={CONSULTATION_TITLE_SECOND} />
          <ImageTextCta
            textTitle={CONSULTATION_BANNER_DATA.title}
            textDescription={CONSULTATION_BANNER_DATA.description}
            image={CONSULTATION_BANNER_DATA.image}
            cta={CONSULTATION_BANNER_DATA.cta}
          />
          <Title title={CONSULTATION_TITLE_THIRD} />
          <Workshops data={CONSULTATION_WORKSHOPS_DATA} />
          <Title
            title={CONSULTATION_TITLE_FORM_CONTACT}
            subtitle={CONSULTATION_SUBTITLE_FORM_CONTACT}
          />
          <div className={styles.formWrapper}>
            <FormContact />
            <Spacing marginRightKey="mega" />
            <FormContactIcons />
          </div>
        </div>
      </Container>
    </>
  );
};

export default Consultation;
