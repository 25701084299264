import Container from 'components/Moleculars/Container';
import ImageTextCta from 'components/Moleculars/ImageTextCta';
import { styled } from 'styled-components';

import {
  ABOUT_LUKASZ,
  ABOUT_LUKASZ_FIFTH,
  ABOUT_LUKASZ_FOURTH,
  ABOUT_LUKASZ_SECOND,
  ABOUT_LUKASZ_THIRD,
} from './LukaszSokolowski.constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LukaszSokolowski = () => {
  return (
    <Container>
      <Wrapper>
        <ImageTextCta
          textTitle={ABOUT_LUKASZ.title}
          textDescription={ABOUT_LUKASZ.description}
          image={ABOUT_LUKASZ.image}
        />
        <ImageTextCta
          textTitle={ABOUT_LUKASZ_SECOND.title}
          textDescription={ABOUT_LUKASZ_SECOND.description}
          image={ABOUT_LUKASZ_SECOND.image}
          reversed
        />
        <ImageTextCta
          textTitle={ABOUT_LUKASZ_THIRD.title}
          textDescription={ABOUT_LUKASZ_THIRD.description}
          image={ABOUT_LUKASZ_THIRD.image}
        />
        <ImageTextCta
          textTitle={ABOUT_LUKASZ_FOURTH.title}
          textDescription={ABOUT_LUKASZ_FOURTH.description}
          image={ABOUT_LUKASZ_FOURTH.image}
          reversed
        />
        <ImageTextCta
          textTitle={ABOUT_LUKASZ_FIFTH.title}
          textDescription={ABOUT_LUKASZ_FIFTH.description}
          image={ABOUT_LUKASZ_FIFTH.image}
        />
      </Wrapper>
    </Container>
  );
};

export default LukaszSokolowski;
