import {
  ABOUT_ME_DATA,
  WHY_ME_CTA,
  WHY_ME_HEADLINE,
  WHY_ME_SUBHEADLINE,
} from 'components/HomePage/HomePage.constants';
import Button from 'components/Moleculars/Button';
import Container from 'components/Moleculars/Container';
import Headline from 'components/Moleculars/Headline';
import Spacing from 'components/Moleculars/Spacing';
import Subheadline from 'components/Moleculars/Subheadline';
import useMedia from 'hooks/useMedia';
import useMyTheme from 'hooks/useMyTheme';

import styles from './AboutMe.module.css';
import AboutMeImage from './assets/aboutMe.png';

const AboutMe = () => {
  const { colors } = useMyTheme();
  const { isDesktop } = useMedia();

  return (
    <Container>
      <div className={styles.imageWrapper}>
        <img src={AboutMeImage} alt="About me" />
      </div>
      <div className={styles.textWrapper}>
        <Headline
          size={!isDesktop ? 'four' : 'three'}
          as="h3"
          colors={colors.darkHeader}
          text={WHY_ME_HEADLINE}
        />
        <Spacing marginBottomKey="kilo" />
        <Subheadline
          as="h4"
          text={WHY_ME_SUBHEADLINE}
          size={!isDesktop ? 'two' : 'one'}
        />
        <Spacing marginBottomKey="kilo" />
        <Button
          size="medium"
          label={WHY_ME_CTA}
          backgroundColor={colors.purple}
          textColor={colors.white}
          link="/o-nas"
        />
        <div className={styles.numberWrapper}>
          {ABOUT_ME_DATA.map((data, index) => (
            <div key={`${data.number}-${index}`}>
              <Headline
                size="three"
                as="h4"
                colors={colors.darkHeader}
                text={data.number}
                spanColor={colors.purple}
              />
              <Subheadline as="h5" text={data.text} size="two" />
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default AboutMe;
