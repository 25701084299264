import BoxesWitIconAndText from 'components/Moleculars/BoxesWitIconAndText';
import Container from 'components/Moleculars/Container';
import FormContact from 'components/Moleculars/FormContact';
import FormContactIcons from 'components/Moleculars/FormContactIcons';
import Headline from 'components/Moleculars/Headline';
import MainBanner from 'components/Moleculars/MainBanner';
import ServiceCards from 'components/Moleculars/ServiceCards';
import Spacing from 'components/Moleculars/Spacing';
import Subheadline from 'components/Moleculars/Subheadline';
import Title from 'components/Moleculars/Title';
import useMedia from 'hooks/useMedia';
import useMyTheme from 'hooks/useMyTheme';

import {
  BANNER_HEADLINE,
  BANNER_SUBHEADLINE,
  PAGES_WHY_US_DATA,
  PAGES_WWW_CONTACT_FORM_TITLE,
  PAGES_WWW_WHY_DO_YOU_NEED_DATA,
  PAGES_WWW_WHY_DO_YOU_NEED_DESCRIPTION,
  PAGES_WWW_WHY_DO_YOU_NEED_PIXELS_DATA,
  PAGES_WWW_WHY_DO_YOU_NEED_PIXELS_DESCRIPTION,
  PAGES_WWW_WHY_DO_YOU_NEED_PIXELS_TITLE,
  PAGES_WWW_WHY_DO_YOU_NEED_TITLE,
  PAGES_WWW_WHY_US,
} from './PagesWWW.constants';
import styles from './PagesWWW.module.css';

const PagesWWW = () => {
  const { isMobile } = useMedia();
  const { colors } = useMyTheme();

  return (
    <>
      <MainBanner title={BANNER_HEADLINE} description={BANNER_SUBHEADLINE} />
      <Container>
        <div className={styles.wrapper}>
          <Title title={PAGES_WWW_WHY_DO_YOU_NEED_TITLE} />
          <Subheadline
            text={PAGES_WWW_WHY_DO_YOU_NEED_DESCRIPTION}
            as="h3"
            size={isMobile ? 'two' : 'one'}
            center
            className={styles.headline}
          />
          <BoxesWitIconAndText data={PAGES_WWW_WHY_DO_YOU_NEED_DATA} />
        </div>
      </Container>
      <div className={styles.waveWrapper}>
        <Headline
          size={isMobile ? 'four' : 'one'}
          as="h2"
          text={PAGES_WWW_WHY_DO_YOU_NEED_PIXELS_TITLE}
          colors={colors.white}
          center
        />
      </div>
      <Container>
        <div className={styles.wrapper}>
          <Subheadline
            text={PAGES_WWW_WHY_DO_YOU_NEED_PIXELS_DESCRIPTION}
            as="h3"
            size={isMobile ? 'two' : 'one'}
            center
            className={styles.headline}
          />
          <BoxesWitIconAndText data={PAGES_WWW_WHY_DO_YOU_NEED_PIXELS_DATA} />
          <Title title={PAGES_WWW_WHY_US} />
          <ServiceCards services={PAGES_WHY_US_DATA} />
          <Title title={PAGES_WWW_CONTACT_FORM_TITLE} />
          <div className={styles.formWrapper}>
            <FormContact />
            <Spacing marginRightKey="mega" />
            <FormContactIcons />
          </div>
        </div>
      </Container>
    </>
  );
};

export default PagesWWW;
