import { useEffect } from 'react';

import {
  ColorsInterface,
  SpacingInterface,
} from 'assets/styles/theme.interface';
import useMyTheme from 'hooks/useMyTheme';
import styled from 'styled-components';

const NotificationContainer = styled.div<{
  variant: 'error' | 'success' | 'sending' | null;
  colors: ColorsInterface;
  spacing: SpacingInterface;
}>`
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  background: ${({ colors }) => colors.white};
  border: ${({ variant, colors }) =>
    variant === 'success' ? `1px solid ${colors.blue}` : '1px solid #F44336'};
  color: ${({ colors }) => colors.darkHeader};
  padding: ${({ spacing }) => spacing.mega};
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  justify-content: center;
  min-width: 400px;
  transition: all 0.5s ease-in-out;

  ${({ theme }) => theme.mq.tablet} {
    min-width: 300px;
    width: 90%;
  }
`;

const CloseButton = styled.button<{ colors: ColorsInterface }>`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: ${({ colors }) => colors.darkHeader};
  font-size: 16px;
  cursor: pointer;
`;

interface NotificationProps {
  variant: 'error' | 'success' | 'sending' | null;
  onClick: () => void;
}

const Notification = ({ variant, onClick }: NotificationProps) => {
  const { colors, spacing } = useMyTheme();
  const duration = 6000;

  useEffect(() => {
    const timer = setTimeout(() => {
      onClick();
    }, duration);

    return () => clearTimeout(timer);
  }, [onClick]);

  return (
    <NotificationContainer variant={variant} colors={colors} spacing={spacing}>
      {variant === 'success'
        ? 'Dziękujemy za zapis do newslettera!'
        : 'Wystąpił błąd podczas zapisu do newslettera'}
      <CloseButton onClick={onClick} colors={colors}>
        X
      </CloseButton>
    </NotificationContainer>
  );
};

export default Notification;
