import WatchIcon from 'assets/icons/watch.svg?react';

import KampaniaNaFacebookuImage from './assets/kampania_na_facebooku.jpeg';

export const COURSES_TITLE = 'Kursy i materiały online';
export const COURSES_DESCRIPTION = `W moje kursy i materiały online przelewam całą wiedzę i doświadczenie jakie zdobyłem w czasie kilkunastu lat pracy jako marketingowiec.
Mam nadzieję, że pomogę Ci sprawniej realizować Twoje marketingowe cele i marzenia.`;

export const KAMPANIA_NA_FACEBOOKU = {
  icon: WatchIcon,
  type: 'Kurs video:',
  title: 'Kampania na Facebooku od A do Z',
  description:
    'Możesz dotrzeć do swoich klientów dzięki Facebook Ads a ja pokażę Ci jak to zrobić.',
  cta: 'Zobacz więcej',
  image: KampaniaNaFacebookuImage,
  link: 'https://kursy.lukaszsokolowski.com/kampania-na-facebooku',
};
