import React, { useEffect } from 'react';

import {
  ColorsInterface,
  SpacingInterface,
} from 'assets/styles/theme.interface';
import useMedia from 'hooks/useMedia';
import useMyTheme from 'hooks/useMyTheme';
import usePostsStore from 'hooks/usePostsStore';
import styled from 'styled-components';

import Button from '../Button';
import Headline from '../Headline';
import Spacing from '../Spacing';
import Subheadline from '../Subheadline';

const PostContainer = styled.div<{ spacing: SpacingInterface }>`
  display: flex;
  flex-direction: row;
  padding: ${({ spacing }) => spacing.mega};
  height: 100%;
  gap: ${({ spacing }) => spacing.mega};

  ${({ theme }) => theme.mq.tablet} {
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const LargeColumn = styled(Column)`
  flex: 2;
`;

const PostCard = styled.div<{
  spacing: SpacingInterface;
  colors: ColorsInterface;
  large?: boolean;
  noImages?: boolean;
}>`
  background-color: ${({ colors }) => colors.white};
  border: 1px solid ${({ colors }) => colors.lightGrayBackground};
  border-radius: 8px;
  margin-bottom: ${({ spacing }) => spacing.mega};
  padding: ${({ spacing }) => spacing.mega};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: ${({ large, noImages }) => {
    if (noImages) return '200px';
    if (large) return '627px';
    return '410px';
  }};
`;

const StyledLink = styled.a`
  text-decoration: none;
`;

interface Post {
  title: string;
  date: string;
  url: string;
  summary: string;
  image: string;
}

const LatestPostsV2 = () => {
  const { posts, fetchPosts } = usePostsStore((state) => ({
    posts: state.posts,
    fetchPosts: state.fetchPosts,
  }));

  const { colors, spacing } = useMyTheme();
  const { isMobile } = useMedia();

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  return (
    <>
      <PostContainer spacing={spacing}>
        <Column>
          {posts.slice(0, 2).map((post: Post, index: number) => (
            <PostCard
              key={post.url}
              spacing={spacing}
              colors={colors}
              noImages={index % 2 !== 0}
            >
              {(isMobile || index % 2 === 0) && (
                <img
                  src={post.image}
                  alt={post.title}
                  style={{ width: '100%', height: 'auto' }}
                />
              )}
              <Spacing marginBottomKey="mega" />
              <Headline
                size="five"
                as="h2"
                text={post.title}
                colors={colors.darkHeader}
              />
              <Spacing marginBottomKey="mega" />
              <StyledLink href={post.url}>
                <Button
                  size="medium"
                  label="Czytaj więcej"
                  backgroundColor={colors.purple}
                  textColor={colors.white}
                  link={post.url}
                />
              </StyledLink>
            </PostCard>
          ))}
        </Column>
        <Column>
          {posts.slice(2, 4).map((post: Post, index: number) => (
            <PostCard
              key={post.url}
              spacing={spacing}
              colors={colors}
              noImages={index % 2 !== 0}
            >
              {(isMobile || index % 2 === 0) && (
                <img
                  src={post.image}
                  alt={post.title}
                  style={{ width: '100%', height: 'auto' }}
                />
              )}
              <Spacing marginBottomKey="mega" />
              <Headline
                size="five"
                as="h2"
                text={post.title}
                colors={colors.darkHeader}
              />
              <Spacing marginBottomKey="mega" />
              <StyledLink href={post.url}>
                <Button
                  size="medium"
                  label="Czytaj więcej"
                  backgroundColor={colors.purple}
                  textColor={colors.white}
                  link={post.url}
                />
              </StyledLink>
            </PostCard>
          ))}
        </Column>
        <LargeColumn>
          {posts.slice(4, 5).map((post: Post) => (
            <PostCard
              key={post.url}
              spacing={spacing}
              colors={colors}
              large={!isMobile}
            >
              <img
                src={post.image}
                alt={post.title}
                style={{ width: '100%', height: 'auto' }}
              />
              <Spacing marginBottomKey="mega" />
              <div>
                <Headline
                  size="five"
                  as="h2"
                  text={post.title}
                  colors={colors.darkHeader}
                />
                <Spacing marginBottomKey="kilo" />
                {!isMobile && (
                  <Subheadline as="h3" text={post.summary} size="two" />
                )}
              </div>
              <Spacing marginBottomKey="mega" />
              <StyledLink href={post.url}>
                <Button
                  size="medium"
                  label="Czytaj więcej"
                  backgroundColor={colors.purple}
                  textColor={colors.white}
                  link={post.url}
                />
              </StyledLink>
            </PostCard>
          ))}
        </LargeColumn>
      </PostContainer>
    </>
  );
};

export default LatestPostsV2;
