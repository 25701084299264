import { create } from 'zustand';

interface FormErrors {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  company: string;
  subject: string;
  message: string;
}
interface FormState {
  formData: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    company: string;
    subject: string;
    message: string;
  };
  errors: FormErrors;
  showModal: boolean;
  setFormField: (field: keyof FormState['formData'], value: string) => void;
  setFormError: (field: keyof FormErrors, message: string) => void;
  setShowModal: (show: boolean) => void;
  resetForm: () => void;
  resetErrors: () => void;
}

const initialErrors: FormErrors = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  company: '',
  subject: '',
  message: '',
};

const useFormStore = create<FormState>((set) => ({
  formData: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    company: '',
    subject: '',
    message: '',
  },
  errors: initialErrors,
  showModal: false,
  setFormField: (field, value) =>
    set((state) => ({
      formData: { ...state.formData, [field]: value },
    })),
  setFormError: (field, message) =>
    set((state) => ({
      errors: { ...state.errors, [field]: message },
    })),
  setShowModal: (show) => set({ showModal: show }),
  resetForm: () =>
    set({
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: '',
        subject: '',
        message: '',
      },
    }),
  resetErrors: () =>
    set({
      errors: initialErrors,
    }),
}));

export default useFormStore;
