import ContactPageIcon from 'assets/icons/contact_page.svg?react';
import Container from 'components/Moleculars/Container';
import FormContact from 'components/Moleculars/FormContact';
import FormContactIcons from 'components/Moleculars/FormContactIcons';
import Headline from 'components/Moleculars/Headline';
import Spacing from 'components/Moleculars/Spacing';
import Subheadline from 'components/Moleculars/Subheadline';
import useMedia from 'hooks/useMedia';
import useMyTheme from 'hooks/useMyTheme';

import { HEADLINE, SUBHEADLINE, TEXT_ITEMS } from './ContactPage.constants';
import styles from './ContactPage.module.css';

const ContactPage = () => {
  const { colors } = useMyTheme();
  const { isMobile } = useMedia();

  return (
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.headlineWrapper}>
          <ContactPageIcon />
          <Spacing marginBottomKey="tera" />
          <Headline
            size={isMobile ? 'four' : 'three'}
            as="h1"
            colors={colors.darkHeader}
            text={HEADLINE}
          />
          <Subheadline size="two" as="h2" text={SUBHEADLINE} />
          <Spacing marginBottomKey="tera" />
          <div className={styles.textsWrapper}>
            {TEXT_ITEMS.map((item, index) => (
              <div key={index}>
                <item.icon />
                <Subheadline size="two" as="h3" text={item.text} />
                <Spacing marginBottomKey={isMobile ? 'giga' : 'bit'} />
              </div>
            ))}
          </div>
        </div>
        <Spacing marginBottomKey="zetta" />
        <div className={styles.formWrapper}>
          <FormContact />
          <Spacing marginRightKey="mega" />
          <FormContactIcons />
        </div>
      </div>
    </Container>
  );
};

export default ContactPage;
