import { useState } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import {
  ColorsInterface,
  SpacingInterface,
} from 'assets/styles/theme.interface';
import useMyTheme from 'hooks/useMyTheme';
import styled from 'styled-components';

import Button from '../Button';
import Container from '../Container';
import Notification from '../Notification';

const NewsletterWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FormWrapper = styled.form<{
  colors: ColorsInterface;
  spacing: SpacingInterface;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid ${({ colors }) => colors.darkHeader};
  border-radius: 20px;
  padding: 37px ${({ spacing }) => spacing.peta};
  width: 1049px;

  ${({ theme }) => theme.mq.smallDesktop} {
    width: 100%;
  }

  ${({ theme }) => theme.mq.tablet} {
    width: 100%;
    flex-direction: column;
    padding: ${({ spacing }) => spacing.kilo};
    border: none;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input<{ spacing: SpacingInterface }>`
  width: 240px;
  margin-right: ${({ spacing }) => spacing?.peta};
  padding: 14px 16px;
  font-weight: 800;

  ${({ theme }) => theme.mq.tablet} {
    margin-right: 0;
    margin-bottom: ${({ spacing }) => spacing.kilo};
  }
`;

const ErrorText = styled.div<{ spacing: SpacingInterface }>`
  color: red;
  margin-top: 5px;
  font-size: 12px;
  margin-top: ${({ spacing }) => spacing.kilo};
`;

interface MyFormFields {
  EMAIL: string;
  FNAME: string;
}

const Newsletter = () => {
  const { colors, spacing } = useMyTheme();
  const postUrl = import.meta.env.VITE_MAILCHIMP_URL;

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [errors, setErrors] = useState({ email: '', name: '' });
  const [displayNotification, setDisplayNotification] = useState(false);

  const validateField = (fieldName: string, value: string) => {
    let error = '';
    const emailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    switch (fieldName) {
      case 'email':
        if (!emailReg.test(value)) {
          error = 'Proszę wpisać poprawny e-mail.';
        }
        break;
      case 'name':
        if (value.length < 3) {
          error = 'Imię musi mieć przynajmniej 3 znaki.';
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: error }));
    return error === '';
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setName(value);
    validateField('name', value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
    validateField('email', value);
  };

  const closeNotification = () => setDisplayNotification(false);

  return (
    <Container>
      <NewsletterWrapper>
        <MailchimpSubscribe<MyFormFields>
          url={postUrl}
          render={({ subscribe, status }) => {
            const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              if (
                validateField('name', name) &&
                validateField('email', email)
              ) {
                subscribe({ EMAIL: email, FNAME: name });
                setErrors({ email: '', name: '' });
                if (status !== 'sending') {
                  setDisplayNotification(true);
                }
              }
            };

            return (
              <>
                <FormWrapper
                  colors={colors}
                  spacing={spacing}
                  onSubmit={handleSubmit}
                >
                  <InputWrapper>
                    <Input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Imię"
                      spacing={spacing}
                      value={name}
                      onChange={handleNameChange}
                    />
                    {errors.name && (
                      <ErrorText spacing={spacing}>{errors.name}</ErrorText>
                    )}
                  </InputWrapper>
                  <InputWrapper>
                    <Input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="E-mail"
                      spacing={spacing}
                      value={email}
                      onChange={handleEmailChange}
                    />
                    {errors.email && (
                      <ErrorText spacing={spacing}>{errors.email}</ErrorText>
                    )}
                  </InputWrapper>
                  <Button
                    size="medium"
                    type="submit"
                    label="Dołączam"
                    backgroundColor={colors.yellow}
                    disabled={
                      name === '' || email === '' || !email.includes('@')
                    }
                  />
                </FormWrapper>
                {displayNotification && (
                  <Notification variant={status} onClick={closeNotification} />
                )}
              </>
            );
          }}
        />
      </NewsletterWrapper>
    </Container>
  );
};

export default Newsletter;
