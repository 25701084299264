import { ChangeEvent, FormEvent } from 'react';

import { useForm } from '@formspree/react';
import useFormStore from 'hooks/useFormStore';
import useMedia from 'hooks/useMedia';
import useMyTheme from 'hooks/useMyTheme';

import Button from '../Button';
import FieldsRow from './components/FieldsRow';
import Modal from './components/Modal';
import {
  FORM_BTN_CTA,
  FORM_COMPANY,
  FORM_COMPANY_PLACEHOLDER,
  FORM_EMAIL,
  FORM_EMAIL_PLACEHOLDER,
  FORM_LAST_NAME,
  FORM_LAST_NAME_PLACEHOLDER,
  FORM_MESSAGE,
  FORM_MESSAGE_PLACEHOLDER,
  FORM_NAME,
  FORM_NAME_PLACEHOLDER,
  FORM_PHONE,
  FORM_PHONE_PLACEHOLDER,
  FORM_SUBJECT,
  FORM_SUBJECT_PLACEHOLDER,
} from './FormContact.constants';
import styles from './FormContact.module.css';
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validateMessage,
} from './FormContact.service';

const FormContact = () => {
  const { colors } = useMyTheme();
  const { isMobile } = useMedia();

  const {
    formData,
    errors,
    showModal,
    setFormField,
    setFormError,
    setShowModal,
    resetForm,
    resetErrors,
  } = useFormStore();
  const [state, handleSubmit] = useForm(import.meta.env.VITE_FORMSPREE_ID);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;

    if (name in formData) {
      setFormField(name as keyof typeof formData, value);

      switch (name) {
        case 'firstName':
          setFormError('firstName', validateFirstName(value));
          break;
        case 'lastName':
          setFormError('lastName', validateLastName(value));
          break;
        case 'email':
          setFormError('email', validateEmail(value));
          break;
        case 'message':
          setFormError('message', validateMessage(value));
          break;
        case 'company':
          break;
        case 'subject':
          break;
        default:
          break;
      }
    }
  };

  const formSubmit = (event: FormEvent) => {
    event.preventDefault();
    const firstNameError = validateFirstName(formData.firstName);
    const lastNameError = validateLastName(formData.lastName);
    const emailError = validateEmail(formData.email);
    const messageError = validateMessage(formData.message);

    setFormError('firstName', firstNameError);
    setFormError('lastName', lastNameError);
    setFormError('email', emailError);
    setFormError('message', messageError);

    if (!firstNameError && !lastNameError && !emailError && !messageError) {
      handleSubmit(formData);
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    resetForm();
    resetErrors();
  };

  const isFormInvalid = (): boolean => {
    return (
      !!errors.firstName ||
      !!errors.lastName ||
      !!errors.email ||
      !!errors.message ||
      !formData.firstName.trim() ||
      !formData.lastName.trim() ||
      !formData.email.trim() ||
      !formData.message.trim()
    );
  };

  return (
    <div className={styles.formWrapper}>
      <form onSubmit={formSubmit}>
        {!isMobile ? (
          <div className={styles.row}>
            <FieldsRow
              id="firstName"
              name="firstName"
              label={FORM_NAME}
              placeholder={FORM_NAME_PLACEHOLDER}
              value={formData.firstName}
              error={errors.firstName}
              onChange={handleChange}
            />
            <FieldsRow
              id="lastName"
              name="lastName"
              label={FORM_LAST_NAME}
              placeholder={FORM_LAST_NAME_PLACEHOLDER}
              value={formData.lastName}
              error={errors.lastName}
              onChange={handleChange}
            />
          </div>
        ) : (
          <>
            <FieldsRow
              id="firstName"
              name="firstName"
              label={FORM_NAME}
              placeholder={FORM_NAME_PLACEHOLDER}
              value={formData.firstName}
              error={errors.firstName}
              onChange={handleChange}
            />
            <FieldsRow
              id="lastName"
              name="lastName"
              label={FORM_LAST_NAME}
              placeholder={FORM_LAST_NAME_PLACEHOLDER}
              value={formData.lastName}
              error={errors.lastName}
              onChange={handleChange}
            />
          </>
        )}
        {!isMobile ? (
          <div className={styles.row}>
            <FieldsRow
              id="email"
              name="email"
              label={FORM_EMAIL}
              placeholder={FORM_EMAIL_PLACEHOLDER}
              value={formData.email}
              error={errors.email}
              type="email"
              onChange={handleChange}
            />
            <FieldsRow
              id="phone"
              name="phone"
              label={FORM_PHONE}
              placeholder={FORM_PHONE_PLACEHOLDER}
              type="tel"
              onChange={handleChange}
              value={formData.phone}
            />
          </div>
        ) : (
          <>
            <FieldsRow
              id="email"
              name="email"
              label={FORM_EMAIL}
              placeholder={FORM_EMAIL_PLACEHOLDER}
              value={formData.email}
              error={errors.email}
              type="email"
              onChange={handleChange}
            />
            <FieldsRow
              id="phone"
              name="phone"
              label={FORM_PHONE}
              placeholder={FORM_PHONE_PLACEHOLDER}
              type="tel"
              onChange={handleChange}
              value={formData.phone}
            />
          </>
        )}
        <div className={styles.row}>
          <div className={styles.formField}>
            <label className={styles.label} htmlFor="company">
              {FORM_COMPANY}
            </label>
            <input
              className={styles.input}
              type="text"
              id="company"
              name="company"
              placeholder={FORM_COMPANY_PLACEHOLDER}
              onChange={handleChange}
              value={formData.company}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.formField}>
            <label className={styles.label} htmlFor="subject">
              {FORM_SUBJECT}
            </label>
            <input
              className={styles.input}
              type="text"
              id="subject"
              name="subject"
              placeholder={FORM_SUBJECT_PLACEHOLDER}
              onChange={handleChange}
              value={formData.subject}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.formField}>
            <label className={styles.label} htmlFor="message">
              {FORM_MESSAGE}
            </label>
            <textarea
              className={styles.textarea}
              id="message"
              name="message"
              placeholder={FORM_MESSAGE_PLACEHOLDER}
              onChange={handleChange}
              value={formData.message}
            ></textarea>
            {errors.message && (
              <span style={{ color: 'red' }}>{errors.message}</span>
            )}
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            type="submit"
            size="medium"
            label={FORM_BTN_CTA}
            backgroundColor={colors.yellow}
            disabled={state.submitting || isFormInvalid()}
          />
        </div>
      </form>
      <Modal show={showModal} onClose={closeModal} />
    </div>
  );
};

export default FormContact;
