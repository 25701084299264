import { useContext } from 'react';

import { ThemeContext } from 'styled-components';

import { MyThemeInterface } from '../theme';

const useMyTheme = (): MyThemeInterface => {
  const theme = useContext(ThemeContext) as MyThemeInterface;
  return {
    colors: theme.colors,
    spacing: theme.spacing,
    mq: theme.mq,
  };
};

export default useMyTheme;
