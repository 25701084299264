import { SpacingInterface } from 'assets/styles/theme.interface';
import useMyTheme from 'hooks/useMyTheme';
import { styled } from 'styled-components';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const SmallFormField = styled.div<{
  spacing: SpacingInterface;
}>`
  width: 100%;
  margin-bottom: ${({ spacing }) => spacing.mega};

  ${({ theme }) => theme.mq.smallDesktop} {
    margin-bottom: ${({ spacing }) => spacing.mega};
  }

  ${({ theme }) => theme.mq.mobile} {
    margin-bottom: ${({ spacing }) => spacing.mega};
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

interface FieldsRowProps {
  id: string;
  name: string;
  label?: string;
  placeholder?: string;
  value?: string;
  type?: string;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FieldsRow = ({
  id,
  name,
  label = '',
  placeholder = '',
  value = '',
  type = 'text',
  error = '',
  onChange = () => {},
}: FieldsRowProps) => {
  const { spacing } = useMyTheme();

  return (
    <Row>
      <SmallFormField spacing={spacing}>
        {label && <Label htmlFor={id}>{label}</Label>}
        <Input
          type={type}
          id={id}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
        {error && <span style={{ color: 'red' }}>{error}</span>}
      </SmallFormField>
    </Row>
  );
};

export default FieldsRow;
