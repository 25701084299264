import bookIcon from 'assets/icons/book.svg?react';
import brainIcon from 'assets/icons/brain.svg?react';
import bullsEyeIcon from 'assets/icons/bullseye.svg?react';
import chartLineIcon from 'assets/icons/chartLine.svg?react';
import codeBranchIcon from 'assets/icons/codeBranch.svg?react';
import magnifyingGlassChartIcon from 'assets/icons/magnifying_glass_chart.svg?react';
import peopleArrowsIcon from 'assets/icons/peopleArrows.svg?react';

import boskaNailsImage from './assets/boskaNails.png';
import ecoCarImage from './assets/ecoCar.png';
import primaveraHomeImage from './assets/primaveraHome.png';

export const BANNER_HEADLINE = 'Kampanie Social Media';
export const BANNER_SUBHEADLINE =
  'Skuteczne kampanie płatne w social media mogą znacząco zwiększyć widoczność Twojej marki oraz być doskonałym źrodłem sprzedaży Twoich produktów. Łączymy kreatywność z analitycznym podejściem, aby Twoje kampanie były zarówno efektowne, jak i efektywne.';

export const SOCIAL_MEDIA_TITLE = 'Dlaczego warto wybrać nasze usługi?';

export const SOCIAL_MEDIA_DATA = [
  {
    icon: bookIcon,
    title: 'Doświadczenie i wiedza',
    text: 'Posiadamy szerokie doświadczenie w prowadzeniu kampanii na różnych platformach social media, takich jak TikTok i Meta (Facebook, Instagram). Nasz zespół zna najnowsze trendy i narzędzia, które zapewniają sukces kampanii.',
  },
  {
    icon: bullsEyeIcon,
    title: 'Personalizowane strategie',
    text: 'Tworzymy strategie dopasowane do specyficznych potrzeb i celów Twojej marki, co pozwala na maksymalizację efektów kampanii.',
  },
  {
    icon: magnifyingGlassChartIcon,
    title: 'Optymalizacja i Analiza',
    text: 'Regularnie monitorujemy i optymalizujemy kampanie, aby zapewnić najwyższy zwrot z inwestycji (ROI) oraz dostarczać raporty, które pomogą Ci lepiej zrozumieć efektywność działań.',
  },
];

export const SOCIAL_MEDIA_TITLE_SECOND =
  'Jak działa proces prowadzenia kampanii';

export const SOCIAL_MEDIA_EXAMPLES = [
  {
    name: 'Wstępna konsultacja',
    icon: peopleArrowsIcon,
    description:
      'Poznajemy Twoje cele i oczekiwania, aby przygotować indywidualną strategię kampanii.',
  },
  {
    name: 'Planowanie i kreacja',
    icon: brainIcon,
    description:
      'Tworzymy plan kampanii oraz przygotowujemy kreatywne materiały reklamowe.',
  },
  {
    name: 'Implementacja',
    icon: codeBranchIcon,
    description:
      'Uruchamiamy kampanię, monitorujemy jej przebieg i wprowadzamy niezbędne optymalizacje.',
  },
  {
    name: 'Raportowanie i wsparcie',
    icon: chartLineIcon,
    description:
      'Regularnie raportujemy wyniki kampanii oraz oferujemy dodatkowe wsparcie i rekomendacje.',
  },
];

export const SOCIAL_MEDIA_TITLE_FORM_CONTACT =
  'Zacznijmy od rozmowy o kampaniach';

export const SOCIAL_MEDIA_EXAMPLES_FIRST = {
  title: 'Kampanie dla <span>Boska Nails</span>',
  description: `Kampanie efektwynościowe, których celem jest sprzedaż. Obsługa w kilku systemach reklamowych. Optymalizacja kreacji video do Social Media w celu zwiększenia skuteczności działań marketingowych.`,
  image: boskaNailsImage,
};

export const SOCIAL_MEDIA_EXAMPLES_SECOND = {
  title: 'Kampania dla  <span>EcoCar</span>',
  description: `Kampania Meta Ads z wykorzystaniem Lead Ads, której celem było pozyskanie wysokiej jakości leadów z grupy odbiorców o wąskiej specjalizacji.`,
  image: ecoCarImage,
};

export const SOCIAL_MEDIA_EXAMPLES_THIRD = {
  title: 'Kampanie dla  <span>Primavera Home</span>',
  description: `Prowadzenie kampanii performance oraz brand awareness w systemach TikTok Ads i Meta Ads. Optymalizacja kreacji reklamowych pod kątem poprawy efektywności.  `,
  image: primaveraHomeImage,
};
