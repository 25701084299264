import {
  BANNER_INFO_CTA,
  BANNER_INFO_HEADLINE,
  BANNER_INFO_SUBHEADLINE,
} from 'components/HomePage/HomePage.constants';
import Button from 'components/Moleculars/Button';
import Container from 'components/Moleculars/Container';
import Headline from 'components/Moleculars/Headline';
import Subheadline from 'components/Moleculars/Subheadline';
import useMyTheme from 'hooks/useMyTheme';

import styles from './InfoBanner.module.css';

const InfoBanner = () => {
  const { colors } = useMyTheme();

  return (
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.copyWrapper}>
          <div className={styles.styledText}>
            <Headline
              size="three"
              as="h3"
              colors={colors.darkHeader}
              spanColor={colors.purple}
              text={BANNER_INFO_HEADLINE}
            />
            <Subheadline as="h4" text={BANNER_INFO_SUBHEADLINE} size="two" />
          </div>
          <div className={styles.imageWrapper}></div>
        </div>
        <Button
          size="medium"
          label={BANNER_INFO_CTA}
          backgroundColor={colors.yellow}
          link="/kontakt"
        />
      </div>
    </Container>
  );
};

export default InfoBanner;
