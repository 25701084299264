import { useNavigate } from 'react-router-dom';

import CursorIcon from 'assets/icons/cursor.svg?react';
import { OFFERS_DATA } from 'components/HomePage/HomePage.constants';
import Button from 'components/Moleculars/Button';
import Container from 'components/Moleculars/Container';
import Headline from 'components/Moleculars/Headline';
import useMedia from 'hooks/useMedia';
import useMyTheme from 'hooks/useMyTheme';
import useNavigationStore from 'hooks/useNavigationStore';

import styles from './Offers.module.css';

const Offers = () => {
  const { colors } = useMyTheme();
  const { isDesktop } = useMedia();
  const navigate = useNavigate();
  const { setActiveUrl } = useNavigationStore();

  const handleClick = (url: string) => {
    if (url) {
      navigate(url);
      setActiveUrl(url);
    }
  };

  return (
    <Container>
      <div className={`${styles.offersWrapper} section offers`}>
        {OFFERS_DATA.map((offer, index) => (
          <div className={styles.box} key={`${offer.title}-${index}`}>
            <div className={styles.titleWrapper}>
              <Headline
                as="h3"
                text={offer.title}
                size={isDesktop ? 'four' : 'five'}
                colors={colors.darkHeader}
                center
              />
            </div>
            <Button
              size={isDesktop ? 'medium' : 'small'}
              label={offer.btn_cta}
              backgroundColor={colors.yellow}
              icon={<CursorIcon />}
              onClick={() => handleClick(offer.url)}
            />
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Offers;
