import { useRef } from 'react';

import QuoteIcon from 'assets/icons/quote.svg?react';
import { TESTIMONIALS_DATA } from 'components/HomePage/HomePage.constants';
import Headline from 'components/Moleculars/Headline';
import Subheadline from 'components/Moleculars/Subheadline';
import useMedia from 'hooks/useMedia';
import useMyTheme from 'hooks/useMyTheme';
import SwiperCore from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './Testimonials.module.css';

const Testimonials = () => {
  const { colors } = useMyTheme();
  const { isMobile, isSmallDesktop } = useMedia();

  const swiperRef = useRef<SwiperCore>();

  const displaySlides = () => {
    if (isMobile) return 1;
    if (isSmallDesktop) return 2;
    return 3;
  };

  return (
    <div className={styles.testimonialsWrapper}>
      <Swiper
        modules={[Autoplay, Pagination, Navigation, Scrollbar]}
        spaceBetween={50}
        slidesPerView={displaySlides()}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        autoplay={{
          delay: 1,
          disableOnInteraction: true,
        }}
        speed={9000}
        effect="slide"
        direction={'horizontal'}
        loop={true}
        freeMode={true}
      >
        {TESTIMONIALS_DATA.map((testimonial, index) => (
          <SwiperSlide key={`${testimonial.name}-${index}`}>
            <div className={styles.testimonialsBox}>
              <div className={styles.quoteWrapper}>
                <QuoteIcon />
              </div>
              <div className={styles.titleWrapper}>
                <Headline
                  size="four"
                  as="h4"
                  colors={colors.darkHeader}
                  center
                  text={testimonial.title}
                />
              </div>
              <div className={styles.descriptionWrapper}>
                <Subheadline
                  size="two"
                  as="h5"
                  text={testimonial.text}
                  center
                />
              </div>
              <div className={styles.nameWrapper}>
                <Headline
                  size="five"
                  as="h4"
                  colors={colors.darkHeader}
                  center
                  text={testimonial.name}
                />
                <Headline
                  size="five"
                  as="h5"
                  colors={colors.darkHeader}
                  center
                  text={testimonial.company}
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Testimonials;
