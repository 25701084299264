import { MyThemeInterface } from '../../theme';

export const theme: MyThemeInterface = {
  colors: {
    purple: '#6a4c93',
    blue: '#26C6DA',
    yellow: '#FFC107',
    lightBlue: '#D3E5FD',
    lightGrayBackground: '#F1F6FD',
    black: '#1a1a1a',
    white: '#fff',
    gray: '#f4f2f5',
    darkHeader: '#1F1F26',
    darkSubheader: '#656464',
  },

  spacing: {
    bit: '8px',
    byte: '8px',
    kilo: '12px',
    mega: '16px',
    giga: '24px',
    tera: '32px',
    peta: '40px',
    exa: '48px',
    zetta: '56px',
    etta: '80px',
    yotta: '100px',
    xotta: '180px',
  },

  mq: {
    mobile: '@media (max-width: 767px)',
    tablet: '@media (max-width: 967px)',
    smallDesktop: '@media (min-width: 768px) and (max-width: 1024px)',
    desktop: '@media (min-width: 1200px)',
  },
};
