import { SpacingInterface } from 'assets/styles/theme.interface';
import useMyTheme from 'hooks/useMyTheme';
import { styled } from 'styled-components';

const ContainerWrapper = styled.div<{ spacing: SpacingInterface }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0 80px;
  margin: ${({ spacing }) => spacing.zetta} auto;

  ${({ theme }) => theme.mq.tablet} {
    flex-direction: column;
    padding: 0 ${({ spacing }) => spacing.mega};
    align-items: center;
  }

  ${({ theme }) => theme.mq.mobile} {
    margin: ${({ spacing }) => spacing.giga} auto;
  }
`;

const Container = ({ children }: { children: React.ReactNode }) => {
  const { spacing } = useMyTheme();

  return <ContainerWrapper spacing={spacing}>{children}</ContainerWrapper>;
};

export default Container;
