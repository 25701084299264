import computerIcon from 'assets/icons/computer.svg?react';
import dataIcon from 'assets/icons/data.svg?react';
import heartIcon from 'assets/icons/heart.svg?react';
import locationIcon from 'assets/icons/location.svg?react';
import mobileScreenButton from 'assets/icons/mobile_screen_button.svg?react';
import moneyCheckDollarIcon from 'assets/icons/money_check_dollar.svg?react';
import userIcon from 'assets/icons/user.svg?react';

import link4Image from './assets/link4.png';
import corporateBannerImage from './assets/szkolenia_dla_firm_lukasz_sokolowski.png';
import viessmannImage from './assets/viessmann.png';
import wroclawImage from './assets/wroclaw.png';

export const BANNER_HEADLINE = 'Szkolenia dla firm';
export const BANNER_SUBHEADLINE =
  'W Purple wierzymy, że wiedza to potęga. Nasze szkolenia z digital marketingu dla firm są zaprojektowane tak, aby wyposażyć Twój zespół w najnowsze umiejętności i strategie, które pomogą w osiągnięciu sukcesu w dynamicznie zmieniającym się świecie marketingu cyfrowego.';

export const CORPORATE_TRAINING_TITLE =
  'Dlaczego warto skorzystać z naszych szkoleń? ';

export const CORPORATE_TRAINING_DATA = [
  {
    icon: userIcon,
    title: 'Aktualna <br>wiedza',
    text: 'Nasze szkolenia obejmują najnowsze trendy i narzędzia w digital marketingu, dzięki czemu Twój zespół będzie na bieżąco z najnowszymi praktykami.',
  },
  {
    icon: locationIcon,
    title: 'Praktyczne umiejętności',
    text: 'Stawiamy na praktyczne podejście, zapewniając uczestnikom możliwość zdobycia realnych umiejętności, które mogą od razu zastosować w swojej pracy.',
  },
  {
    icon: dataIcon,
    title: 'Dostosowane programy',
    text: 'Oferujemy szkolenia dostosowane do specyficznych potrzeb Twojej firmy i branży, zapewniając maksymalną efektywność nauki.',
  },
];

export const CORPORATE_TITLE_SECOND = 'Jak działa proces szkolenia?';

export const CORPORATE_BANNER_DATA = {
  title:
    'Szkolenia realizowane stancjonarnie w całej Polsce jak również podczas sesji online. ',
  description: `Przygotowujemy program szkolenia dostosowany do specyficznych potrzeb Twojej firmy. Realizujemy szkolenie w dogodnym dla Ciebie miejscu i czasie, zapewniając interaktywne i angażujące sesje. Oferujemy dodatkowe wsparcie i konsultacje po szkoleniu, aby pomóc w implementacji zdobytej wiedzy. Każdy uczestnik uzyskuje certyfikat uczestnictwa w szkoleniu.
      <br><strong>
      Skontaktuj się z nami, aby omówić swoje potrzeby szkoleniowe i zaplanować program.</strong>`,
  cta: 'Napisz do nas',
  image: corporateBannerImage,
};

export const CORPORATE_TITLE_THIRD = 'Przykładowe tematy szkoleń';
export const CORPORATE_SUBTITLE_THIRD =
  'Poniżej znajdziesz przykładowe tematy szkoleń, które już prowadziłem i mogę przeprowadzić dla Twojej firmy';

export const CORPORATE_WORKSHOPS_DATA = [
  {
    icon: mobileScreenButton,
    title: 'Pionowe formaty video (TikTok, Rolki, Shortsy)',
  },
  {
    icon: computerIcon,
    title: 'Prwadzenie skutecznych kampanii reklmowych Meta Ads',
  },
  {
    icon: heartIcon,
    title: 'Jak budować efektywną komunikację Social Media',
  },
  {
    icon: moneyCheckDollarIcon,
    title: 'Jak zarządzać budżetem  marketingowym w firmie',
  },
];

export const CORPORATE_TITLE_FORM_CONTACT = 'Zacznijmy od rozmowy';

export const CORPORATE_EXAMPLES_FIRST = {
  title: 'Szkolenie realizowane dla <span>LINK4</span>',
  description: `Specjalnie dla firmy LINK4 przygotowaliśmy szkolenie <span><strong>"Reklama płatna na TikToku"</strong></span>. Szkolenie było dedykowane dla zespołów marketingowych, które chciały zgłębić tajniki efektywnych kampanii w tej dynamicznie rozwijającej się platformie. Poruszyliśmy kluczowe aspekty tworzenia angażujących treści, targetowania reklam i analizy wyników, które są niezbędne do skutecznej promocji.`,
  image: link4Image,
};

export const CORPORATE_EXAMPLES_SECOND = {
  title: 'Szkolenie realizowane dla <span>VIESSMANN</span>',
  description: `Dla VIESSMANN przeprowadziliśmy specjalistyczne szkolenie z zakresu <span><strong>"Digital Marketing w praktyce"</strong></span>. Uczestnicy, w tym menedżerowie i specjaliści ds. marketingu, mieli okazję zgłębić techniki i strategie skutecznej komunikacji w środowiskach cyfrowych. Omówiliśmy metody optymalizacji kampanii, wykorzystanie danych do personalizacji ofert oraz najlepsze praktyki w pracy Digital Marketera.`,
  image: viessmannImage,
};

export const CORPORATE_EXAMPLES_THIRD = {
  title: 'Szkolenie realizowane dla <span>wroclaw.pl</span>',
  description: `Dla portalu wroclaw.pl przeprowadziliśmy warsztaty poświęcone <span><strong>"Pionowym formatom video w social mediach"</strong></span>. Szkolenie skupiało się na wykorzystaniu wideo w pionowych formatach jako narzędzia do zwiększania zaangażowania odbiorców i promocji lokalnych inicjatyw. Omówiliśmy strategie tworzenia treści, które są dopasowane do oczekiwań użytkowników mediów społecznościowych, w tym najlepsze praktyki dotyczące storytellingu i technik montażowych.`,
  image: wroclawImage,
};
