import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import AboutPurple from 'components/AboutPurple';
import Consultation from 'components/Consultation';
import ContactPage from 'components/ContactPage';
import CorporateTrainingPage from 'components/CorporateTrainingPage';
import CoursesPage from 'components/CoursesPage';
import Footer from 'components/Footer';
import FreeAuditPage from 'components/FreeAuditPage';
import NoContent from 'components/NoContent';
import PagesWWW from 'components/PagesWWW';
import ScrollToTop from 'components/ScrollToTop';
import SocialMediaCampain from 'components/SocialMediaCampain';
import LukaszSokolowski from 'components/Team/LukaszSokolowski';
import { createGlobalStyle } from 'styled-components';

import HomePage from './components/HomePage';
import Navigation from './components/Navigation/';
import useNavigationStore from './hooks/useNavigationStore';
import './styles/css/main.css';

const NoScroll = createGlobalStyle<{ isActive: boolean }>`
  body {
    overflow: ${({ isActive }) => (isActive ? 'hidden' : 'auto')};
  }
`;

const App = () => {
  const { isActiveMobileMenu } = useNavigationStore();

  return (
    <Router>
      <NoScroll isActive={isActiveMobileMenu} />
      <ScrollToTop />
      <Navigation />
      <Routes>
        {/* The routes will be added here */}
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<NoContent />} />
        <Route path="/kursy" element={<CoursesPage />} />
        <Route path="/szkolenia-dla-firm" element={<CorporateTrainingPage />} />
        <Route path="/bezplatny-audyt" element={<FreeAuditPage />} />
        <Route path="/kampanie-social-media" element={<SocialMediaCampain />} />
        <Route path="/konsultacje" element={<Consultation />} />
        <Route path="/strony-www" element={<PagesWWW />} />
        {/* <Route path="/newsletter" element={<Newsletter />} /> */}
        <Route path="/o-nas" element={<AboutPurple />} />
        <Route path="/kontakt" element={<ContactPage />} />
        <Route path="/team/lukasz-sokolowski" element={<LukaszSokolowski />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
