export const FORM_NAME = 'Imię';
export const FORM_NAME_PLACEHOLDER = 'Wpisz swoje imię';
export const FORM_LAST_NAME = 'Nazwisko';
export const FORM_LAST_NAME_PLACEHOLDER = 'Wpisz swoje nazwisko';
export const FORM_EMAIL = 'Email';
export const FORM_EMAIL_PLACEHOLDER = 'Wpisz swój email';
export const FORM_PHONE = 'Telefon';
export const FORM_PHONE_PLACEHOLDER = 'Wpisz swój numer telefonu';
export const FORM_COMPANY = 'Twoja nazwa firmy lub rodzaj działalności';
export const FORM_COMPANY_PLACEHOLDER =
  'Wpisz nazwę firmy lub rodzaj działalności';
export const FORM_SUBJECT = 'Temat';
export const FORM_SUBJECT_PLACEHOLDER = 'Wpisz temat';
export const FORM_MESSAGE = 'Wiadomość';
export const FORM_MESSAGE_PLACEHOLDER = 'Wpisz swoją wiadomość';
export const FORM_BTN_CTA = 'Wyślij wiadomość';
