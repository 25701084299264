import PhoneIcon from 'assets/icons/phone.svg?react';
import {
  BANNER_BOX_DATA,
  CTA_BANNER,
  HEADLINE_BANNER,
} from 'components/HomePage/HomePage.constants';
import Button from 'components/Moleculars/Button';
import Container from 'components/Moleculars/Container';
import Headline from 'components/Moleculars/Headline';
import Subheadline from 'components/Moleculars/Subheadline';
import useMedia from 'hooks/useMedia';
import useMyTheme from 'hooks/useMyTheme';

import styles from './Banner.module.css';

const Banner = () => {
  const { colors } = useMyTheme();
  const { isTablet } = useMedia();

  return (
    <Container>
      <div className={styles.bannerContent}>
        <div className={styles.ctaWrapper}>
          <Headline
            as="h3"
            text={HEADLINE_BANNER}
            size={!isTablet ? 'two' : 'three'}
            colors={colors.darkHeader}
            className={styles.headline}
          />
          <Button
            size="medium"
            label={CTA_BANNER}
            backgroundColor={colors.yellow}
            icon={<PhoneIcon />}
            link="/kontakt"
          />
        </div>
        <div className={styles.boxsWrapper}>
          {BANNER_BOX_DATA.map((box, index) => (
            <div className={styles.box} key={`${box.text}-${index}`}>
              <box.icon />
              <div className={styles.subheadlineWrapper}>
                <Subheadline
                  size="one"
                  as="h4"
                  text={box.text}
                  textColor={colors.darkSubheader}
                  center
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Banner;
