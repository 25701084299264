import {
  ColorsInterface,
  SpacingInterface,
} from 'assets/styles/theme.interface';
import useMyTheme from 'hooks/useMyTheme';
import styled from 'styled-components';
import { useTheme } from 'styled-components';

import Headline from '../Headline';
import Subheadline from '../Subheadline';

const CardWrapper = styled.div<{ image: string; colors: ColorsInterface }>`
  width: 280px;
  height: 280px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: top;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border: 1px solid ${({ colors }) => colors.lightGrayBackground};
  transition: background-size 0.3s ease;

  &:hover {
    background-size: 120%;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${({ theme }) => theme.colors.purple};
      opacity: 0.2;
    }
  }
`;

const CardText = styled.div<{
  colors: ColorsInterface;
  spacing: SpacingInterface;
}>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: ${({ colors }) => colors.white};
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  z-index: 2;
  padding: ${({ spacing }) => spacing.kilo};

  &::after {
    position: absolute;
    content: '';
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 110%;
    background: linear-gradient(0deg, #000, transparent);
    z-index: -1;
    opacity: 0.75;
  }
`;

interface TeamCardProps {
  name: string;
  title: string;
  image: string;
}

const TeamCard = ({ name, title, image }: TeamCardProps) => {
  const theme = useTheme();
  const { colors, spacing } = useMyTheme();

  return (
    <CardWrapper image={image} colors={colors}>
      <CardText colors={colors} spacing={spacing}>
        <Headline
          as="h3"
          text={name}
          colors={theme.colors.white}
          size="five"
          center
        />
        <Subheadline
          as="h4"
          text={title}
          center
          textColor={colors.white}
          size="three"
        />
      </CardText>
    </CardWrapper>
  );
};

export default TeamCard;
