import { ColorsInterface } from 'assets/styles/theme.interface';
import {
  MODAL_CTA,
  MODAL_HEADLINE,
  MODAL_SUBHEADLINE,
} from 'components/ContactPage/ContactPage.constants';
import Button from 'components/Moleculars/Button';
import Spacing from 'components/Moleculars/Spacing';
import Subheadline from 'components/Moleculars/Subheadline';
import useMyTheme from 'hooks/useMyTheme';
import styled from 'styled-components';

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div<{ colors: ColorsInterface }>`
  background: ${({ colors }) => colors.white};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 400px;
  text-align: center;
  ${({ theme }) => theme.mq.mobile} {
    width: 100%;
  }
`;

interface ModalProps {
  show: boolean;
  onClose: () => void;
}

const Modal = ({ show, onClose }: ModalProps) => {
  const { colors } = useMyTheme();

  if (!show) {
    return null;
  }

  return (
    <ModalBackdrop>
      <ModalContent colors={colors}>
        <Subheadline as="h3" text={MODAL_HEADLINE} size="two" />
        <Subheadline as="h3" text={MODAL_SUBHEADLINE} size="two" />
        <Spacing marginBottomKey="mega" />
        <Button
          size="medium"
          label={MODAL_CTA}
          onClick={onClose}
          backgroundColor={colors.yellow}
        />
      </ModalContent>
    </ModalBackdrop>
  );
};

export default Modal;
