import dataIcon from 'assets/icons/data.svg?react';
import glassChartIcon from 'assets/icons/glass_chart.svg?react';
import locationIcon from 'assets/icons/location.svg?react';
import moneyCheckDollar from 'assets/icons/money_check_dollar.svg?react';
import painBrushIcon from 'assets/icons/paint_brush.svg?react';
import peopleGroupIcon from 'assets/icons/people_group.svg?react';
import userIcon from 'assets/icons/user.svg?react';

export const BANNER_HEADLINE = 'Bezpłatny audyt kont reklamowych';
export const BANNER_SUBHEADLINE =
  'Pierwszym krokiem do skutecznej kampanii marketingowej jest dogłębna analiza. Nasz bezpłatny audyt kont reklamowych to doskonała okazja, aby zidentyfikować obszary do poprawy i zoptymalizować Twoje działania marketingowe.';

export const AUDIT_TITLE = 'Dlaczego warto skorzystać z naszego audytu?';

export const AUDIT_DATA = [
  {
    icon: userIcon,
    title: 'Bezpłatna <br>ekspertyza',
    text: 'Oferujemy profesjonalną analizę Twoich kont reklamowych na TikToku, Meta, LinkedIn, YouTube zupełnie za darmo.',
  },
  {
    icon: locationIcon,
    title: 'Szczegółowa diagnostyka',
    text: 'Nasz audyt obejmuje szczegółowe sprawdzenie wszystkich aspektów Twoich kampanii, w tym ustawień kont, grup docelowych, kreacji reklamowych i wskaźników efektywności.',
  },
  {
    icon: dataIcon,
    title: 'Personalizowane Rekomendacje',
    text: 'Na podstawie wyników audytu otrzymasz spersonalizowane zalecenia, które pomogą Ci osiągnąć lepsze wyniki.',
  },
];

export const AUDIT_TITLE_SECOND =
  'Przeprowadzamy szczegółowy audyt i przygotowujemy raport z wynikami';

export const AUDIT_EXAMPLES = [
  {
    name: 'Analiza ustawień konta',
    icon: glassChartIcon,
    description:
      'Sprawdzamy poprawność i efektywność ustawień Twojego konta reklamowego. ',
  },
  {
    name: 'Ocena kreacji reklamowych',
    icon: painBrushIcon,
    description:
      'Analizujemy Twoje kreacje video i grafiki, oceniając ich skuteczność i atrakcyjność.',
  },
  {
    name: 'Przegląd grupy docelowej',
    icon: peopleGroupIcon,
    description:
      'Weryfikujemy, czy Twoje kampanie trafiają do odpowiednich odbiorców i sugerujemy ewentualne korekty.',
  },
  {
    name: 'Optymalizacja budżetu',
    icon: moneyCheckDollar,
    description:
      'Sprawdzamy alokację budżetu reklamowego, identyfikując możliwości oszczędności i lepszego wykorzystania środków.',
  },
];

export const AUDIT_TITLE_FORM_CONTACT = 'Zacznijmy od rozmowy';
