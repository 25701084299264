import LukaszSokolowskiPanelImage from './assets/lukaszsokolowsk_panel.jpeg';
import LukaszSokolowskiImage from './assets/LukaszSokolowski.png';
import LukaszSokolowskiArticleImage from './assets/lukaszsokolowski_article.jpeg';
import LukaszSokolowskiAwwardsImage from './assets/lukaszsokolowski_nagrody.png';
import LukaszSokolowskiTrainingsImage from './assets/lukaszsokolowski_szkolenia.png';

export const ABOUT_LUKASZ = {
  title: 'Łukasz Sokołowski, CEO <span>Purple</span>',
  description: `Przez ponad 13 lat wspierałem firmy w budowaniu skutecznej komunikacji marketingowej. Pracowałem po obu stronach - agencji i klienta zdobywając doświadczenie w takich firmach jak Volvo, Optimum Nutrition, Hortex oraz wielu innych. Moje kampanie dla Huuuge Games osiągały spektakularne wyniki na rynkach międzynarodowych, a zaangażowanie w Social Media dla Citi Handlowy przyniosło nam liczne nagrody branżowe. <br/>
  Po latach pracy, pomagając firmom i klientom indywidualnym w tworzeniu wartościowej komunikacji marketingowej, zdecydowałem, że nadszedł czas na kolejny krok - założenie własnej agencji. Rynek potrzebuje agencji, która połączy wiedzę ekspercką z innowacją i strategią. Tak powstała <strong>Purple, agencja marketingowa, która łączy pasję do marketingu z głębokim zrozumieniem potrzeb klienta</strong>.`,
  image: LukaszSokolowskiImage,
};

export const ABOUT_LUKASZ_SECOND = {
  title: '<span>Konferencje</span>',
  description: `Jestem nie tylko praktykiem, ale również teoretykiem marketingu. Moje doświadczenie w zastosowaniu strategii marketingowych jest prezentowane na renomowanych konferencjach branżowych, gdzie dzielę się swoją wiedzą i umiejętnościami z innymi profesjonalistami.
  <br />
  👉 Golden Marketing Conference Poznań 2020
  👉 Golden Marketing Conference LIVE! vol. 3
  👉 I DESIGN - konferencja on-line
  👉 Golden Marketing Conference 2022
  👉 Social Media Week Warsaw 2022
  👉 InternetBeta 2022
  👉 Targi eHandlu 2023
  👉 eTrade Show 2024
  `,
  image: LukaszSokolowskiPanelImage,
};

export const ABOUT_LUKASZ_THIRD = {
  title: '<span>Nagrody</span>',
  description: `Uznanie w branży potwierdzają również nagrody, które zdobyłem dzięki innowacyjnym kampaniom:
  <br />
  ✅ Mixx Award Europe 2020 - II miejsce w Europie w kategorii „Video”.
  ✅ Golden Arrow 2020 - wyróżnienie w kategorii „Personalizacja” za kampanię na YouTube.
  ✅ Golden Arrow 2022 - wyróżnienie w kategorii „Social Media” za kampanię na Tik-Tok'u.
  ✅ Wyróżnienie w rankingu Widoczni - „100 osobowości marketingu. Ranking 2024” <a href="https://widoczni.com/blog/100-polskich-osobowosci-marketingu-ranking/">czytaj więcej</a>.
  ✅ Wyróżnienie w rankingu Widoczni - „65 polskich tiktokerów 2023: kategoria marketing i biznes” <a href="https://widoczni.com/blog/ranking-polskich-tiktokerow/?utm_source=linkedin&utm_medium=blog&utm_campaign=czerwiec+2023">czytaj więcej</a>.
  `,
  image: LukaszSokolowskiAwwardsImage,
};

export const ABOUT_LUKASZ_FOURTH = {
  title: '<span>Publikacje i podcasty</span>',
  description: `Jestem aktywnie zaangażowany w dzielenie się wiedzą poprzez publikacje w uznanych czasopismach marketingowych oraz udzielanie wywiadów w podcastach.
  <br />
  <strong>Marketer +:</strong>
  ✍️ Większe efekty mniejszym kosztem. Jak optymalizować wykorzystanie budżetu reklamowego - <a href="https://marketerplus.pl/wieksze-efekty-mniejszym-kosztem-jak-optymalizowac-wykorzystanie-budzetu-reklamowego/">czytaj więcej</a> - 27 listopada 2023
  ✍️ Jak wykorzystać video content marketing na TikToku w działaniach B2B - <a href="https://marketerplus.pl/jak-wykorzystac-video-content-marketing-na-tiktoku-w-dzialaniach-b2b/">czytaj więcej</a> - 29 września 2022
  <strong>E-commerce w praktyce:</strong>
  ✍️ Krótkie formaty wideo w służbie e-commerce - <a href="https://ewp.pl/krotkie-formaty-wideo-w-sluzbie-e-commerce/">czytaj więcej</a> - 17 listopada 2022
  <strong>Social Media Trends</strong>
  ✍️ Social Media TrendBook 2024
  <strong>Press - wypowiedź do artykułu</strong>
  ✍️ Eseje na TikToku? Jak dłuższe materiały publicystyczne sprzedać w 30 sekund - <a href="https://www.press.pl/tresc/80563,eseje-na-tiktoku_-jak-dluzsze-materialy-publicystyczne-sprzedac-w-30-sekund">czytaj więcej</a> - 24 lutego 2024
  <strong>Podcasty:</strong>
  🗣️ Pogawędka z Marketerem: Łukasz Sokołowski #Przetargi #Agencje #Digital - Digitalowa Zajawka 360°
  🗣️ TikTok w B2B - Obalamy mity! | Łukasz Sokołowski, Konsultant ds. Marketingu - #004 - Zwinnie o B2B
  <strong>Rozmowy:</strong>
  🗣️ Kreacja, analityka i cenne wskazówki - rozmowa z Łukaszem Sokołowskim o pracy w digital marketingu - <a href="https://notojob.com/blog/kreacja-analityka-i-cenne-wskazowki-rozmowa-z-lukaszem-sokolowskim-o-pracy-w-digital-marketingu/">czytaj więcej</a>
  `,
  image: LukaszSokolowskiArticleImage,
};

export const ABOUT_LUKASZ_FIFTH = {
  title: '<span>Szkolenia</span>',
  description: `Jestem aktywnie zaangażowany w edukację i rozwój umiejętności marketingowych poprzez prowadzenie szkoleń i kursów online, które skupiają się na wykorzystaniu nowoczesnych narzędzi i strategii. Oto niektóre z prowadzonych przeze mnie szkoleń:
  <br />
  <strong>Szkolenie online - Marketer+:</strong>
  🌐 "Rolki, relacje, stories, tiktoki - jak ogarnąć krótkie formy wideo w małej i średniej firmie"
  <strong>Kurs online </strong>
  🌐 "Kampania na Facebooku od A do Z" - <a href="https://kursy.lukaszsokolowski.com/kampania-na-facebooku">zobacz więcej</a>
  <strong>Kurs online na żywo - Practima:</strong>
  🌐 "Social Media Manager - poziom zaawansowany" - <a href="https://www.practima.io/pl/kurs-smm-pro">zobacz więcej</a>
  `,
  image: LukaszSokolowskiTrainingsImage,
};
