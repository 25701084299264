import create from 'zustand';

interface Post {
  title: string;
  date: string;
  url: string;
  summary: string;
  image: string;
}

interface PostsState {
  posts: Post[];
  setPosts: (posts: Post[]) => void;
  fetchPosts: () => Promise<void>;
}

const usePostsStore = create<PostsState>((set) => ({
  posts: [],
  setPosts: (posts) => set({ posts }),
  fetchPosts: async () => {
    try {
      // const response = await fetch('/api/index.json');
      const response = await fetch(
        'https://blog.lukaszsokolowski.com/index.json',
      );
      const data = await response.json();
      set({ posts: data.posts });
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  },
}));

export default usePostsStore;
