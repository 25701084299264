import {
  ColorsInterface,
  SpacingInterface,
} from 'assets/styles/theme.interface';
import useMyTheme from 'hooks/useMyTheme';
import styled from 'styled-components';

import Headline from '../Headline';
import Subheadline from '../Subheadline';

const BoxWrapper = styled.div<{
  colors: ColorsInterface;
  spacing: SpacingInterface;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ colors }) => colors.lightGrayBackground};
  padding: ${({ spacing }) => spacing.giga};
  width: 100%;
  ${({ theme }) => theme.mq.tablet} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: ${({ spacing }) => spacing.giga};
  }
`;

const Box = styled.div<{
  spacing: SpacingInterface;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: ${({ spacing }) => spacing.kilo};
  ${({ theme }) => theme.mq.tablet} {
    gap: 0;
    width: 100%;
    align-items: center;
    margin-bottom: ${({ spacing }) => spacing.mega};
  }
`;

const CustomBoxWrapper = ({
  boxesData,
}: {
  boxesData: { number: string; text: string }[];
}) => {
  const { colors, spacing } = useMyTheme();

  return (
    <BoxWrapper colors={colors} spacing={spacing}>
      {boxesData.map((box) => (
        <Box key={box.number} spacing={spacing}>
          <Headline
            size="three"
            as="h3"
            colors={colors.darkHeader}
            text={box.number}
            spanColor={colors.blue}
          />
          <Subheadline
            as="h4"
            text={box.text}
            darkSubheader={colors.darkSubheader}
            size="two"
          />
        </Box>
      ))}
    </BoxWrapper>
  );
};

export default CustomBoxWrapper;
