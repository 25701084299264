import ChartIcon from 'assets/icons/chart.svg?react';
import dataIcon from 'assets/icons/data.svg?react';
import locationIcon from 'assets/icons/location.svg?react';
import PlugPlusIcon from 'assets/icons/plug_plus.svg?react';
import PuzzleIcon from 'assets/icons/puzzle.svg?react';
import TalkIcon from 'assets/icons/talk.svg?react';
import userIcon from 'assets/icons/user.svg?react';

import consultationBannerImage from './assets/konsultacje_indywidulane_lukasz_sokolowski.png';

export const BANNER_HEADLINE = 'Konsultacje indywidualne';
export const BANNER_SUBHEADLINE =
  'W Purple, wierzymy, że indywidualne podejście do każdego klienta jest kluczem do osiągnięcia najlepszych wyników. Nasze konsultacje 1 na 1 to idealna okazja, aby zagłębić się w specyficzne potrzeby Twojej marki i wypracować skuteczne strategie, które przyniosą realne rezultaty biznesowe.';

export const CONSULTATION_TITLE = 'Co zyskujesz dzięki konsultacjom';

export const CONSULTATION_OFFER_DATA = [
  {
    icon: userIcon,
    title: '<strong>Personalizowane Doradztwo</strong>',
    text: 'Każda konsultacja jest dostosowana do Twoich unikalnych potrzeb i celów. Skupiamy się na tym, co jest najważniejsze dla Twojej marki.',
  },
  {
    icon: locationIcon,
    title: '<strong>Ekspercka Wiedza</strong>',
    text: 'Dzięki naszemu doświadczeniu w tworzeniu kreatywnych kreacji video i prowadzeniu efektywnych kampanii płatnych na platformach takich jak TikTok i Meta, otrzymasz najwyższej jakości porady, które możesz natychmiast wdrożyć.',
  },
  {
    icon: dataIcon,
    title: '<strong>Kreatywność i Analiza</strong>',
    text: 'Łączymy kreatywne podejście z analitycznym myśleniem, aby Twoja marka nie tylko została zauważona, ale i zapamiętana przez odpowiednią grupę odbiorców.',
  },
];

export const CONSULTATION_TITLE_SECOND = 'Co oferujemy podczas konsultacji?';

export const CONSULTATION_BANNER_DATA = {
  title: 'Dowiesz się jak budować strategię komunikacji.',
  description: `W czasie spotkania podpowiemy jak podejść do tworzenia kreacji video, oraz jak optymalizacować kampanie płatne, aby przynosiły dla Ciebie najlepszy wynik. Uzyskasz wskazówki, jak efektywnie zarządzać kampaniami i swoją obecnością na TikTok, Instagramie i Facebooku, aby maksymalizować ROI.`,
  cta: 'Porozmawiajmy',
  image: consultationBannerImage,
};

export const CONSULTATION_TITLE_THIRD = 'Jak działamy?';

export const CONSULTATION_WORKSHOPS_DATA = [
  {
    icon: TalkIcon,
    title:
      '<strong>Wstępna konsultacja</strong> <br>Poznajemy Twoje cele i oczekiwania, aby lepiej zrozumieć Twoje potrzeby.',
  },
  {
    icon: ChartIcon,
    title:
      '<strong>Dogłębna analiza</strong> <br>Analizujemy Twoją obecną strategię i działania, aby zidentyfikować kluczowe obszary do poprawy.',
  },
  {
    icon: PuzzleIcon,
    title:
      '<strong>Strategiczne doradztwo</strong> <br>Przedstawiamy konkretne rekomendacje i plan działania dostosowany do Twojej marki.',
  },
  {
    icon: PlugPlusIcon,
    title:
      '<strong>Wdrożenie i monitorowanie</strong> <br>Pomagamy w implementacji strategii i monitorujemy postępy, aby zapewnić, że osiągasz zamierzone cele.',
  },
];

export const CONSULTATION_TITLE_FORM_CONTACT =
  'Porozmawiajmy o Twoim biznesie online ';
export const CONSULTATION_SUBTITLE_FORM_CONTACT =
  'Poniżej znajdziesz przykładowe tematy szkoleń, które już prowadziłem i mogę przeprowadzić dla Twojej firmy';
