import Button from 'components/Moleculars/Button';
import useMyTheme from 'hooks/useMyTheme';

import style from './NoContent.module.css';

const NoContent = () => {
  const { colors } = useMyTheme();

  return (
    <div className={style.notFoundWrapper}>
      <span className={style.notFoundSpan}> 404 </span>
      <h1 className={style.notFoundTitle}>Strona nie znaleziona</h1>
      <div className={style.notFoundContent}>
        <p className={style.notFoundDescription}>
          Wygląda na to, że strona, której szukasz, nie istnieje. Możesz wrócić
          na stronę główną, klikając w przycisk poniżej.
        </p>
        <Button
          size="medium"
          label={'Wróć do strony głównej'}
          link="/"
          backgroundColor={colors.purple}
          textColor={colors.white}
        />
      </div>
    </div>
  );
};

export default NoContent;
