import { SpacingInterface } from 'assets/styles/theme.interface';
import useMedia from 'hooks/useMedia';
import useMyTheme from 'hooks/useMyTheme';
import { styled } from 'styled-components';

import Container from '../Container';
import Headline from '../Headline';
import Spacing from '../Spacing';
import Subheadline from '../Subheadline';

const TitleWrapper = styled.div<{ spacing: SpacingInterface }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 800px;
  margin: ${({ spacing }) => spacing.exa} 0;

  ${({ theme }) => theme.mq.tablet} {
    width: 100%;
    text-align: center;
    margin: ${({ spacing }) => spacing.mega} 0;
  }
`;

const Title = ({ title, subtitle }: { title: string; subtitle?: string }) => {
  const { colors, spacing } = useMyTheme();
  const { isMobile } = useMedia();

  return (
    <Container>
      <TitleWrapper spacing={spacing}>
        <Headline
          size={isMobile ? 'four' : 'one'}
          as="h2"
          text={title}
          colors={colors.darkHeader}
          center
        />
        {subtitle && (
          <>
            <Spacing marginBottomKey="mega" />
            <Subheadline as="h3" text={subtitle} center size="two" />
          </>
        )}
      </TitleWrapper>
    </Container>
  );
};

export default Title;
