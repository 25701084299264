import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
   *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }



  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    padding: 0;
    margin: 0;
    font-family: "DM Sans", sans-serif;
    font-optical-sizing: auto;
  }

  p {
    font-size: 16px;
    font-weight: 400;
  }

  a {
    text-decoration: none;
  }

  .swiper-scrollbar {
    background: #D3E5FD;
  }

  .swiper-scrollbar-drag {
    background: #6a4c93 ;
  }

  .swiper-wrapper{
    transition-timing-function : linear;
  }
`;
