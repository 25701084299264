import ClockIcon from 'assets/icons/clock.svg?react';
import LightningIcon from 'assets/icons/lightning.svg?react';
import StarsIcon from 'assets/icons/stars.svg?react';

export const HEADLINE =
  'Napisz do mnie i umów się na bezpłatne, 30 minutowe konsultacje marketingowe';
export const SUBHEADLINE =
  'Napisz też w każdej innej sprawie, którą uważasz za ważną';

export const TEXT_ITEMS = [
  {
    icon: ClockIcon,
    text: 'Odpowiadam w 24h',
  },
  {
    icon: LightningIcon,
    text: 'Szybko ocenisz, jak mogę Ci pomóc',
  },
  {
    icon: StarsIcon,
    text: 'Mam elastyczny grafik ',
  },
];

export const SOCIAL_MEDIA = 'Social Media';

export const MODAL_HEADLINE = 'Wiadomość została wysłana!';
export const MODAL_SUBHEADLINE =
  'Niebawem na nią odpowiem. Życzę Ci wspaniałego dnia!';
export const MODAL_CTA = 'Zamknij';
