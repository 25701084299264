import AchievementIcon from 'assets/icons/achievement.svg?react';
import BranIcon from 'assets/icons/brain.svg?react';
import bullsEyeIcon from 'assets/icons/bullseye.svg?react';
import ChartLineIcon from 'assets/icons/chartLine.svg?react';
import ClockRotateLeftIcon from 'assets/icons/clock_rotate_left.svg?react';
import HandHoldingHandIcon from 'assets/icons/hand_holding_hand.svg?react';
import LayersIcon from 'assets/icons/layers.svg?react';
import LightBulbIcon from 'assets/icons/lightbulb.svg?react';
import MountainSunIcon from 'assets/icons/mountain_sun.svg?react';
import RocketIcon from 'assets/icons/rocket.svg?react';
import UsersIcon from 'assets/icons/users.svg?react';

import LukaszSokolowskiImage from './assets/lukaszsokolowski.jpeg';

export const HEADLINE = 'Dlaczego Purple? Odkryj naszą misję';

export const PURPLE_MISSION_DATA = [
  {
    icon: MountainSunIcon,
    title: 'Przekraczamy granice',
    text: 'Naszą misja w Purple jest przekraczanie granic zwykłego marketingu, by tworzyć historie, które nie tylko sprzedają, ale i inspirują. Każda strategia, którą opracowujemy, ma na celu przynieść naszym klientom coś więcej niż zwykłe zyski - chcemy, by każda kampania była przełomowa, definiująca markę i jej przyszłość.',
  },
  {
    icon: LightBulbIcon,
    title: 'Rewolucyjne podejście',
    text: 'Nasze podejście jest proste, ale rewolucyjne: zamiast opierać się na tradycyjnych metodach, łączymy innowacje z doświadczeniem, co pozwala nam wyprzedzać zmiany na rynku i dostarczać naszym klientom rozwiązania, które są nie tylko skuteczne, ale także niezapomniane.',
  },
  {
    icon: bullsEyeIcon,
    title: 'Zrozumienie i personalizacja',
    text: 'W Purple każdy projekt zaczyna się od głębokiego zrozumienia historii, celów i aspiracji naszych klientów. To pozwala nam tworzyć unikalne, spersonalizowane strategie marketingowe, które nie tylko oddziałują na emocje odbiorców, ale także budują długotrwałe relacje między markami a ich klientami.',
  },
];

export const WHY_TRUST_US_TITLE = 'Dlaczego warto nam zaufać?';
export const TRUST_US_DATA = [
  {
    icon: AchievementIcon,
    text: 'Skupiamy się na osiąganiu realnych rezultatów, które przekładają się na sukces naszych klientów.',
  },
  {
    icon: LayersIcon,
    text: 'Pracujemy z warstwami danych i behawioralnych analiz, by dopasować strategie do rzeczywistych potrzeb użytkowników.',
  },
  {
    icon: UsersIcon,
    text: 'Stawiamy na ludzi. Nasze działania marketingowe są human-centric, skoncentrowane na realnych potrzebach i emocjach odbiorców.',
  },
  {
    icon: RocketIcon,
    text: 'Innowacyjność i kreatywność w podejściu do każdego projektu sprawiają, że Twoja marka może dynamicznie rosnąć.',
  },
];

export const OFFERS_TITLE = 'Co oferujemy';
export const WHY_PURPLE_DATA = [
  {
    icon: HandHoldingHandIcon,
    title: 'Relacje',
    description:
      'Każdy projekt traktujemy indywidualnie, angażując się w potrzeby i cele naszych klientów, co skutkuje budowaniem długotrwałych relacji.',
  },
  {
    icon: BranIcon,
    title: 'Eksperckość',
    description:
      'Nasz zespół składa się z ekspertów w różnych dziedzinach, którzy nieustannie poszerzają swoją wiedzę i umiejętności.',
  },
  {
    icon: ClockRotateLeftIcon,
    title: 'Doświadczenie',
    description:
      'Działamy w branży od wielu lat, co pozwala nam efektywnie reagować na zmieniające się warunki rynkowe i oczekiwania klientów.',
  },
  {
    icon: ChartLineIcon,
    title: 'Rezultaty',
    description:
      'Wiele firm, od startupów po wielkie korporacje, przekonało się do naszych usług, osiągając wyznaczone cele dzięki naszym strategiom.',
  },
];

export const LUKASZ_DATA = {
  name: 'Łukasz Sokołowski',
  title: 'CEO',
  image: LukaszSokolowskiImage,
};
export const CONTACT_SECTION_TITLE = 'Skontaktuj się z nami';
