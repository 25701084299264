import styled from 'styled-components';

export interface SpacingProps {
  marginTopKey?: string;
  marginRightKey?: string;
  marginBottomKey?: string;
  marginLeftKey?: string;
}

const SpacingWrapper = styled.div<SpacingProps>`
  ${({
    theme,
    marginTopKey,
    marginRightKey,
    marginBottomKey,
    marginLeftKey,
  }) => `
    margin-top: ${marginTopKey ? theme.spacing[marginTopKey] : '0'};
    margin-right: ${marginRightKey ? theme.spacing[marginRightKey] : '0'};
    margin-bottom: ${marginBottomKey ? theme.spacing[marginBottomKey] : '0'};
    margin-left: ${marginLeftKey ? theme.spacing[marginLeftKey] : '0'};
  `}
`;

const Spacing = ({
  marginTopKey,
  marginRightKey,
  marginBottomKey,
  marginLeftKey,
}: SpacingProps) => {
  return (
    <SpacingWrapper
      marginTopKey={marginTopKey}
      marginRightKey={marginRightKey}
      marginBottomKey={marginBottomKey}
      marginLeftKey={marginLeftKey}
    />
  );
};

export default Spacing;
