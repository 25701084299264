import { Link } from 'react-router-dom';

import LogoIcon from 'assets/icons/logo.svg?react';
import Container from 'components/Moleculars/Container';
import Headline from 'components/Moleculars/Headline';
import Spacing from 'components/Moleculars/Spacing';
import useMyTheme from 'hooks/useMyTheme';
import useNavigationStore from 'hooks/useNavigationStore';

import { FOOTER_DATA } from './Footer.constants';
import styles from './Footer.module.css';

const Footer = () => {
  const { colors } = useMyTheme();
  const { contact, links } = FOOTER_DATA;
  const { setActiveUrl } = useNavigationStore();

  return (
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.brandWrapper}>
          <div className={styles.logo}>
            <Link to="/">
              <LogoIcon title="logo" />
            </Link>
          </div>
          <div className={styles.infoWrapper}>
            <a href={`mailto:${contact.email.address}`}>
              <contact.email.icon />
              <Spacing marginRightKey="kilo" />
              {contact.email.address}
            </a>
          </div>
          <div className={styles.infoWrapper}>
            <a href={`tel:${contact.phone.number}`}>
              <contact.phone.icon />
              <Spacing marginRightKey="kilo" />
              {contact.phone.number}
            </a>
          </div>
        </div>
        <div className={styles.linksWrapper}>
          <div className={styles.linkSection}>
            <div className={styles.headerWrapper}>
              <Headline
                size="five"
                as="h4"
                colors={colors.darkHeader}
                text={links.services.header}
              />
              <Spacing marginBottomKey="kilo" />
            </div>
            {links.services.items.map((service) => (
              <Link
                className={styles.footerLink}
                to={service.url}
                key={service.title}
                onClick={() => setActiveUrl(service.url)}
              >
                {service.title}
              </Link>
            ))}
          </div>
          <div className={styles.linkSection}>
            <div className={styles.headerWrapper}>
              <Headline
                size="five"
                as="h4"
                colors={colors.darkHeader}
                text={links.learnMore.header}
              />
              <Spacing marginBottomKey="kilo" />
            </div>
            {links.learnMore.items.map((info) => (
              <Link
                className={styles.footerLink}
                to={info.url}
                key={info.title}
                onClick={() => setActiveUrl(info.url)}
              >
                {info.title}
              </Link>
            ))}
          </div>
          <div className={styles.linkSection}>
            <div className={styles.headerWrapper}>
              <Headline
                size="five"
                as="h4"
                colors={colors.darkHeader}
                text={links.socialMedia.header}
              />
              <Spacing marginBottomKey="kilo" />
            </div>
            <div className={styles.socialMediaWrapper}>
              {' '}
              {links.socialMedia.items.map((link) => (
                <a href={link.url} key={link.name}>
                  <link.icon />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
