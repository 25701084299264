import isPropValid from '@emotion/is-prop-valid';
import styled, { css } from 'styled-components';

import Body from '../Body';

type ButtonSizeType = 'small' | 'medium' | 'large';

interface ButtonProps {
  size: ButtonSizeType;
  label: string;
  backgroundColor?: string;
  textColor?: string;
  link?: string | undefined;
  icon?: React.ReactElement;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  onClick?: (() => void) | undefined;
}

const baseStyles = ({
  backgroundColor,
  textColor,
  disabled,
}: {
  backgroundColor?: string;
  textColor?: string;
  disabled?: boolean;
}) => css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  margin: 0;
  cursor: ${disabled ? 'not-allowed' : 'pointer'};
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  background-color: ${disabled ? '#F1F6FD' : backgroundColor || 'transparent'};
  box-shadow: 0px 7px 22px -6px rgba(33, 123, 244, 0.34);
  border-radius: 8px;
  border: none;
  color: ${textColor};
  & svg {
    margin-right: 8px;
  }
  ${({ theme }) => theme.mq.tablet} {
    width: 100%;
  }
`;

const smallStyles = ({ size }: { size: ButtonSizeType }) =>
  size === 'small' &&
  css`
    width: 50;
    padding: 4px 8px;
    & p {
      font-size: 12px;
    }
  `;

const mediumStyles = ({ size }: { size: ButtonSizeType }) =>
  size === 'medium' &&
  css`
    width: 169px;
    height: 49px;
    padding: 14px 16px;
    & p {
      font-size: 14px;
    }
  `;

const largeStyles = ({ size }: { size: ButtonSizeType }) =>
  size === 'large' &&
  css`
    width: 300px;
    padding: 32px;
    & p {
      font-size: 30px;
    }
  `;

const BasicButton = styled('button').withConfig({
  shouldForwardProp: (prop) =>
    isPropValid(prop) &&
    prop !== 'textColor' &&
    prop !== 'backgroundColor' &&
    prop !== 'icon',
})<ButtonProps>(baseStyles, smallStyles, mediumStyles, largeStyles);

const Button = (props: ButtonProps) => {
  const { label, textColor, link, icon, type, disabled, onClick } = props;

  const handleClick = (
    redirectPath: string | undefined,
    customOnClick?: () => void,
  ) => {
    if (!disabled) {
      if (customOnClick) {
        customOnClick();
      } else if (redirectPath) {
        window.location.href = redirectPath;
      }
    }
  };

  return (
    <BasicButton
      type={type ? type : 'button'}
      {...props}
      onClick={() => handleClick(link, onClick)}
      disabled={disabled}
    >
      {icon && <span>{icon}</span>}
      <Body textColor={textColor} text={label} />
    </BasicButton>
  );
};

export default Button;
