import { Link } from 'react-router-dom';

import BoxesWitIconAndText from 'components/Moleculars/BoxesWitIconAndText';
import Container from 'components/Moleculars/Container';
import FormContact from 'components/Moleculars/FormContact';
import FormContactIcons from 'components/Moleculars/FormContactIcons';
import Headline from 'components/Moleculars/Headline';
import Spacing from 'components/Moleculars/Spacing';
import Subheadline from 'components/Moleculars/Subheadline';
import TeamCard from 'components/Moleculars/TeamCard';
import Title from 'components/Moleculars/Title';
import useMedia from 'hooks/useMedia';
import useMyTheme from 'hooks/useMyTheme';

import {
  CONTACT_SECTION_TITLE,
  HEADLINE,
  LUKASZ_DATA,
  OFFERS_TITLE,
  PURPLE_MISSION_DATA,
  TRUST_US_DATA,
  WHY_PURPLE_DATA,
  WHY_TRUST_US_TITLE,
} from './AboutPurple.constants';
import styles from './AboutPurple.module.css';

const AboutPurple = () => {
  const { colors } = useMyTheme();
  const { isDesktop } = useMedia();

  return (
    <Container>
      <div className={styles.wrapper}>
        <Title title={HEADLINE} />
        <BoxesWitIconAndText data={PURPLE_MISSION_DATA} />
        <Title title={WHY_TRUST_US_TITLE} />
        <div className={styles.bannerContent}>
          <div className={styles.boxWrapper}>
            {TRUST_US_DATA.map((box, index) => (
              <div className={styles.box} key={`${box.text}-${index}`}>
                <box.icon />
                <div className={styles.subheadlineWrapper}>
                  <Subheadline
                    size="one"
                    as="h4"
                    text={box.text}
                    textColor={colors.darkSubheader}
                    center
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <Title title={OFFERS_TITLE} />
        <div className={`${styles.whyPurpleHeader} section offers`}>
          {WHY_PURPLE_DATA.map((offer, index) => (
            <div className={styles.boxWhy} key={`${offer.title}-${index}`}>
              <div className={styles.titleWrapper}>
                <offer.icon />
                <Headline
                  as="h3"
                  text={offer.title}
                  size={isDesktop ? 'four' : 'five'}
                  colors={colors.darkHeader}
                  center
                />
              </div>
              <Spacing marginBottomKey="mega" />
              <div className={styles.subheadlineWrapper}>
                <Subheadline size="three" as="h4" text={offer.description} />
              </div>
            </div>
          ))}
        </div>
        <Spacing marginBottomKey="zetta" />
        <div className={styles.teamWrapper}>
          <Link to="/team/lukasz-sokolowski">
            <TeamCard
              name={LUKASZ_DATA.name}
              title={LUKASZ_DATA.title}
              image={LUKASZ_DATA.image}
            />
          </Link>
        </div>
        <Title title={CONTACT_SECTION_TITLE} />
        <div className={styles.formWrapper}>
          <FormContact />
          <Spacing marginRightKey="mega" />
          <FormContactIcons />
        </div>
      </div>
    </Container>
  );
};
export default AboutPurple;
