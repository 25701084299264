import Headline from 'components/Moleculars/Headline';
import Spacing from 'components/Moleculars/Spacing';
import Subheadline from 'components/Moleculars/Subheadline';
import useMyTheme from 'hooks/useMyTheme';

import styles from './BoxesWitIconAndText.module.css';

interface BoxWithIconAndTextProps {
  data: {
    icon: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & { title?: string }
    >;
    title: string;
    text: string;
  }[];
}

const BoxesWitIconAndText = ({ data }: BoxWithIconAndTextProps) => {
  const { colors } = useMyTheme();

  return (
    <div className={styles.dataContainer}>
      {data.map((item, index) => (
        <div className={styles.box} key={index}>
          <div className={styles.iconWrapper}>
            <item.icon />
          </div>
          <div className={styles.subheadlineWrapper}>
            <div className={styles.headlineWrapper}>
              <Headline
                size="four"
                as="h3"
                text={item.title}
                colors={colors.darkHeader}
              />
            </div>
            <Spacing marginBottomKey="giga" />
            <Subheadline size="two" as="h3" text={item.text} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default BoxesWitIconAndText;
