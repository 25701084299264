import {
  ColorsInterface,
  SpacingInterface,
} from 'assets/styles/theme.interface';
import useMyTheme from 'hooks/useMyTheme';
import styled from 'styled-components';

import Headline from '../Headline';
import Spacing from '../Spacing';
import Subheadline from '../Subheadline';

const Container = styled.div<{ spacing: SpacingInterface }>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: ${({ spacing }) => spacing.mega};
  align-items: center;
  flex-wrap: wrap;
  row-gap: ${({ spacing }) => spacing.giga};
`;

const Card = styled.div<{ spacing: SpacingInterface; colors: ColorsInterface }>`
  flex-basis: calc(50% - ${({ spacing }) => spacing.giga});
  padding: ${({ spacing }) => spacing.mega};
  margin: ${({ spacing }) => spacing.kilo};
  background-color: ${({ colors }) => colors.white};
  border: 1px solid ${({ colors }) => colors.purple};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
  position: relative;
  height: 300px;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mq.tablet} {
    height: 100%;
    flex-basis: 100%;
    margin: ${({ spacing }) => spacing.kilo} 0;
  }
`;

const IconWrapper = styled.div<{ colors: ColorsInterface }>`
  width: 56px;
  height: 56px;
  border-radius: 6%;
  background-color: ${({ colors }) => colors.white};
  border: 1px solid ${({ colors }) => colors.purple};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -20px;
  left: 20px;

  & svg {
    width: 24px;
    height: 24px;
  }

  & path {
    fill: ${({ colors }) => colors.purple};
  }
`;

const TextWrapper = styled.div<{ spacing: SpacingInterface }>`
  padding: ${({ spacing }) => spacing.mega};
  ${({ theme }) => theme.mq.tablet} {
    padding-top: ${({ spacing }) => spacing.giga};
  }
`;

const HeadlineWrapper = styled.div<{ spacing: SpacingInterface }>`
  ${({ theme }) => theme.mq.mobile} {
    margin-top: ${({ theme }) => theme.spacing.mega};
  }
`;

interface Service {
  name: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  description: string;
}

interface ServiceCardsProps {
  services: Service[];
}

const ServiceCards = ({ services }: ServiceCardsProps) => {
  const { colors, spacing } = useMyTheme();

  return (
    <Container spacing={spacing}>
      {services.map((service, index) => (
        <Card key={index} spacing={spacing} colors={colors}>
          <IconWrapper colors={colors}>
            <service.icon />
          </IconWrapper>
          <TextWrapper spacing={spacing}>
            <HeadlineWrapper spacing={spacing}>
              <Headline
                size="five"
                as="h3"
                text={service.name}
                colors={colors.darkHeader}
              />
            </HeadlineWrapper>
            <Spacing marginBottomKey="mega" />
            <Subheadline as="h4" text={service.description} size="two" />
          </TextWrapper>
        </Card>
      ))}
    </Container>
  );
};

export default ServiceCards;
