import BoxesWitIconAndText from 'components/Moleculars/BoxesWitIconAndText';
import Container from 'components/Moleculars/Container';
import FormContact from 'components/Moleculars/FormContact';
import FormContactIcons from 'components/Moleculars/FormContactIcons';
import MainBanner from 'components/Moleculars/MainBanner';
import ServiceCards from 'components/Moleculars/ServiceCards';
import Spacing from 'components/Moleculars/Spacing';
import Title from 'components/Moleculars/Title';

import {
  AUDIT_DATA,
  AUDIT_EXAMPLES,
  AUDIT_TITLE,
  AUDIT_TITLE_FORM_CONTACT,
  AUDIT_TITLE_SECOND,
  BANNER_HEADLINE,
  BANNER_SUBHEADLINE,
} from './FreeAuditPage.constants';
import styles from './FreeAuditPage.module.css';

const FreeAuditPage = () => {
  return (
    <>
      <MainBanner title={BANNER_HEADLINE} description={BANNER_SUBHEADLINE} />
      <Container>
        <div className={styles.wrapper}>
          <Title title={AUDIT_TITLE} />
          <BoxesWitIconAndText data={AUDIT_DATA} />
          <Spacing marginBottomKey="zetta" />
          <Title title={AUDIT_TITLE_SECOND} />
          <ServiceCards services={AUDIT_EXAMPLES} />
          <Title title={AUDIT_TITLE_FORM_CONTACT} />
          <div className={styles.formWrapper}>
            <FormContact />
            <Spacing marginRightKey="mega" />
            <FormContactIcons />
          </div>
        </div>
      </Container>
    </>
  );
};

export default FreeAuditPage;
