import BoxesWitIconAndText from 'components/Moleculars/BoxesWitIconAndText';
import Container from 'components/Moleculars/Container';
import FormContact from 'components/Moleculars/FormContact';
import FormContactIcons from 'components/Moleculars/FormContactIcons';
import ImageTextCta from 'components/Moleculars/ImageTextCta';
import MainBanner from 'components/Moleculars/MainBanner';
import Spacing from 'components/Moleculars/Spacing';
import Title from 'components/Moleculars/Title';
import Workshops from 'components/Moleculars/Workshops';

import {
  BANNER_HEADLINE,
  BANNER_SUBHEADLINE,
  CORPORATE_BANNER_DATA,
  CORPORATE_EXAMPLES_FIRST,
  CORPORATE_EXAMPLES_SECOND,
  CORPORATE_EXAMPLES_THIRD,
  CORPORATE_SUBTITLE_THIRD,
  CORPORATE_TITLE_FORM_CONTACT,
  CORPORATE_TITLE_SECOND,
  CORPORATE_TITLE_THIRD,
  CORPORATE_TRAINING_DATA,
  CORPORATE_TRAINING_TITLE,
  CORPORATE_WORKSHOPS_DATA,
} from './CorporateTrainingPage.constants';
import styles from './CorporateTrainingPage.module.css';

const CorporateTrainingPage = () => {
  return (
    <>
      <MainBanner title={BANNER_HEADLINE} description={BANNER_SUBHEADLINE} />
      <Container>
        <div className={styles.wrapper}>
          <Title title={CORPORATE_TRAINING_TITLE} />
          <BoxesWitIconAndText data={CORPORATE_TRAINING_DATA} />
          <Title title={CORPORATE_TITLE_SECOND} />
          <ImageTextCta
            textTitle={CORPORATE_BANNER_DATA.title}
            textDescription={CORPORATE_BANNER_DATA.description}
            image={CORPORATE_BANNER_DATA.image}
            cta={CORPORATE_BANNER_DATA.cta}
          />
          <Title
            title={CORPORATE_TITLE_THIRD}
            subtitle={CORPORATE_SUBTITLE_THIRD}
          />
          <Workshops data={CORPORATE_WORKSHOPS_DATA} />
          <ImageTextCta
            textTitle={CORPORATE_EXAMPLES_FIRST.title}
            textDescription={CORPORATE_EXAMPLES_FIRST.description}
            image={CORPORATE_EXAMPLES_FIRST.image}
          />
          <ImageTextCta
            textTitle={CORPORATE_EXAMPLES_SECOND.title}
            textDescription={CORPORATE_EXAMPLES_SECOND.description}
            image={CORPORATE_EXAMPLES_SECOND.image}
            reversed
          />
          <ImageTextCta
            textTitle={CORPORATE_EXAMPLES_THIRD.title}
            textDescription={CORPORATE_EXAMPLES_THIRD.description}
            image={CORPORATE_EXAMPLES_THIRD.image}
          />
          <Title title={CORPORATE_TITLE_FORM_CONTACT} />
          <div className={styles.formWrapper}>
            <FormContact />
            <Spacing marginRightKey="mega" />
            <FormContactIcons />
          </div>
        </div>
      </Container>
    </>
  );
};

export default CorporateTrainingPage;
