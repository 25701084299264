import { SpacingInterface } from 'assets/styles/theme.interface';
import { SOCIAL_MEDIA } from 'components/ContactPage/ContactPage.constants';
import { FOOTER_DATA } from 'components/Footer/Footer.constants';
import useMyTheme from 'hooks/useMyTheme';
import { styled } from 'styled-components';

import Spacing from '../Spacing';
import Subheadline from '../Subheadline';

const FormIcons = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mq.tablet} {
    width: 100%;
  }
`;

const IconWrapper = styled.div<{ spacing: SpacingInterface }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ spacing }) => spacing.etta};
  align-items: center;

  & svg {
    margin: 0 auto;
  }
`;

const SocialMediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SocialMediaIcons = styled.div<{ spacing: SpacingInterface }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;

  & svg {
    width: 24px;
    height: 24px;
    margin-right: ${({ spacing }) => spacing.kilo};
  }
`;

const FormContactIcons = () => {
  const { spacing } = useMyTheme();
  const { contact, links } = FOOTER_DATA;

  return (
    <FormIcons>
      <IconWrapper spacing={spacing}>
        <contact.email.icon />
        <a href={`mailto:${contact.email.address}`}>
          <Spacing marginBottomKey="giga" />
          <Subheadline as="h3" size="two" text={contact.email.address} />
        </a>
      </IconWrapper>
      <IconWrapper spacing={spacing}>
        <contact.phone.icon />
        <a href={`tel:${contact.phone.number}`}>
          <Spacing marginBottomKey="giga" />
          <Subheadline as="h3" size="two" text={contact.phone.number} />
        </a>
      </IconWrapper>
      <IconWrapper spacing={spacing}>
        <contact.location.icon />
        <a href={`${contact.location.address}`}>
          <Spacing marginBottomKey="giga" />
          <Subheadline as="h3" size="two" text={contact.location.address} />
        </a>
      </IconWrapper>
      <SocialMediaWrapper>
        <SocialMediaIcons spacing={spacing}>
          {links.socialMedia.items.map((link) => (
            <a href={link.url} key={link.name}>
              <link.icon />
            </a>
          ))}
        </SocialMediaIcons>
        <Subheadline as="h3" size="two" text={SOCIAL_MEDIA} />
      </SocialMediaWrapper>
    </FormIcons>
  );
};

export default FormContactIcons;
