import { useMemo } from 'react';

import useMyTheme from 'hooks/useMyTheme';
import { sanitize } from 'isomorphic-dompurify';

import styles from './Subheadline.module.css';

type HeadingTagsType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type VariantBodyType = 'highlight' | undefined;
type SubheadlineSize = 'one' | 'two' | 'three';

interface SubheadlineProps {
  as: HeadingTagsType;
  text: string;
  children?: string;
  darkSubheader?: string;
  variant?: VariantBodyType;
  center?: boolean;
  textColor?: string;
  spanColor?: string;
  size?: SubheadlineSize;
  bold?: boolean;
  className?: string;
}

const Subheadline = ({
  as: Tag = 'h3',
  text,
  darkSubheader,
  textColor,
  spanColor,
  size = 'one',
  bold,
  center,
  className = '',
}: SubheadlineProps) => {
  const { colors } = useMyTheme();
  const sanitizedText = useMemo(() => sanitize(text), [text]);

  const combinedClassNames = useMemo(
    () => `
    ${styles.subheadline}
    ${styles[size]}
    ${bold ? styles.bold : ''}
    ${center ? styles.center : ''}
    ${className}
  `,
    [size, bold, center, className],
  );

  const styleOverrides = useMemo(
    () => ({
      color: textColor || darkSubheader || colors.darkSubheader,
      '--span-color': spanColor || 'inherit',
    }),
    [textColor, darkSubheader, spanColor, colors.darkSubheader],
  );

  return (
    <Tag
      className={combinedClassNames}
      style={styleOverrides}
      dangerouslySetInnerHTML={{ __html: sanitizedText }}
    />
  );
};

export default Subheadline;
