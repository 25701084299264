import EnvelopeIcon from 'assets/icons/envelope.svg?react';
import {
  BOXES_DATA,
  BTN_CTA_PRIMARY,
  BTN_CTA_SECONDARY,
  HEADLINE_COPY,
  IDEA_COPY,
  SUBHEADLINE_COPY,
} from 'components/HomePage/HomePage.constants';
import Button from 'components/Moleculars/Button';
import ButtonsGroup from 'components/Moleculars/ButtonsGroup';
import CustomBoxWrapper from 'components/Moleculars/CustomBoxWrapper';
import useMyTheme from 'hooks/useMyTheme';

import MainImage from './assets/mainImage.jpg';
import MainImageWebp from './assets/mainImage.webp';
import styles from './HeroSection.module.css';

interface HeroSectionProps {
  scrollToOffers: () => void;
}

const HeroSection = ({ scrollToOffers }: HeroSectionProps) => {
  const { colors } = useMyTheme();

  return (
    <>
      <div className={`section hero ${styles.wrapper}`}>
        <div className={styles.textWrapper}>
          <h3 className={`${styles.subheadline} ${styles.two}`}>
            {IDEA_COPY}
          </h3>
          <h1 className={`${styles.headline} ${styles.two}`}
          >
            {HEADLINE_COPY}
          </h1>
          <div className={styles.description}>
            <h2 className={styles.subheadline}>
            {SUBHEADLINE_COPY}
            </h2>
          </div>
          <ButtonsGroup>
            <Button
              size="medium"
              label={BTN_CTA_PRIMARY}
              backgroundColor={colors.yellow}
              textColor={colors.darkHeader}
              icon={<EnvelopeIcon />}
              link="/kontakt"
            />
            <Button
              size="medium"
              label={BTN_CTA_SECONDARY}
              textColor={colors.darkHeader}
              onClick={scrollToOffers}
            />
          </ButtonsGroup>
          <div className={styles.customBoxWrapperSmall}>
            <CustomBoxWrapper boxesData={BOXES_DATA} />
          </div>
        </div>
        <div className={styles.imageWrapper}>
          <picture>
          <source srcSet={MainImageWebp} type="image/webp" />
          <img src={MainImage} alt="mainImage" width="602" height="815" />
          </picture>
        </div>
      </div>
      <div className={styles.customBoxWrapper}>
        <CustomBoxWrapper boxesData={BOXES_DATA} />
      </div>
    </>
  );
};

export default HeroSection;
