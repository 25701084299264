import {
  ColorsInterface,
  SpacingInterface,
} from 'assets/styles/theme.interface';
import useMedia from 'hooks/useMedia';
import useMyTheme from 'hooks/useMyTheme';
import { keyframes, styled } from 'styled-components';

import Container from '../Container';
import Headline from '../Headline';
import Spacing from '../Spacing';
import SquaresIcon from './assets/squares.svg?react';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const MainBannerWrapper = styled.div<{ colors: ColorsInterface }>`
  display: flex;
  flex-direction: row;
  position: relative;
  height: 490px;
  width: 100%;
  background: ${({ colors }) => colors.white};
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.4);

  ${({ theme }) => theme.mq.tablet} {
    height: auto;
    margin-top: 78px;
    min-height: 120px;
    align-items: center;
    flex-direction: column;
  }
`;

const IconTopWrapper = styled.div`
  position: absolute;
  top: 2%;
  left: 1%;
  animation: ${fadeIn} 3s ease-in-out;

  ${({ theme }) => theme.mq.tablet} {
    top: 0.2%;
    left: 0.2%;
    svg {
      width: 30%;
      height: auto;
    }
  }
`;

const IconBottomWrapper = styled.div`
  position: absolute;
  bottom: 2%;
  right: 1%;
  rotate: 180deg;
  animation: ${fadeIn} 3s ease-in-out;

  ${({ theme }) => theme.mq.tablet} {
    bottom: 0.2%;
    right: 0.2%;

    svg {
      width: 30%;
      height: auto;
    }
  }
`;

const TextContainer = styled.div<{ spacing: SpacingInterface }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${({ spacing }) => spacing.yotta} ${({ spacing }) => spacing.etta};

  ${({ theme }) => theme.mq.smallDesktop} {
    padding: ${({ spacing }) => spacing.mega};
  }

  ${({ theme }) => theme.mq.tablet} {
    padding: ${({ spacing }) => spacing.mega};
    margin-top: ${({ spacing }) => spacing.giga};
  }
`;

const HeadlineWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SubheadlineWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

interface MainBannerProps {
  title: string;
  description: string;
}

const MainBanner = ({ title, description }: MainBannerProps) => {
  const { colors, spacing } = useMyTheme();
  const { isMobile } = useMedia();

  return (
    <Container>
      <MainBannerWrapper colors={colors}>
        <IconTopWrapper>
          <SquaresIcon />
        </IconTopWrapper>
        <IconBottomWrapper>
          <SquaresIcon />
        </IconBottomWrapper>
        <TextContainer spacing={spacing}>
          <HeadlineWrapper>
            <Headline
              as="h1"
              size={!isMobile ? 'two' : 'four'}
              colors={colors.darkHeader}
              text={title}
              center
              moreSpace
            />
          </HeadlineWrapper>
          {!isMobile && (
            <>
              <Spacing marginBottomKey="exa" />
              <SubheadlineWrapper>
                <Headline
                  as="h2"
                  size="five"
                  text={description}
                  center
                  colors={colors.darkSubheader}
                  moreSpace
                />
              </SubheadlineWrapper>
            </>
          )}
        </TextContainer>
      </MainBannerWrapper>
    </Container>
  );
};

export default MainBanner;
