import {
  ColorsInterface,
  SpacingInterface,
} from 'assets/styles/theme.interface';
import useMyTheme from 'hooks/useMyTheme';
import styled from 'styled-components';

import Subheadline from '../Subheadline';

const WorkshopsContainer = styled.div<{ spacing: SpacingInterface }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ spacing }) => spacing.mega};
  flex-wrap: wrap;
`;

const WorkshopItem = styled.div<{ spacing: SpacingInterface }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 250px;
  margin: ${({ spacing }) => spacing.kilo};

  ${({ theme }) => theme.mq.tablet} {
    width: 100%;
  }
`;

const IconWrapper = styled.div<{
  spacing: SpacingInterface;
  colors: ColorsInterface;
}>`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ spacing }) => spacing.tera};
  border-radius: 50%;
  background-color: ${({ colors }) => colors.purple};

  & svg {
    width: 30px;
    height: 30px;
  }

  & path {
    fill: ${({ colors }) => colors.white};
  }
`;

const TextWrapper = styled.div`
  min-height: 180px;

  ${({ theme }) => theme.mq.tablet} {
    min-height: unset;
  }
`;

interface WorkshopData {
  title: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

interface WorkshopInterface {
  data: WorkshopData[];
}

const Workshops = ({ data }: WorkshopInterface) => {
  const { colors, spacing } = useMyTheme();

  return (
    <WorkshopsContainer spacing={spacing}>
      {data.map((item, index) => (
        <WorkshopItem key={index} spacing={spacing}>
          <IconWrapper spacing={spacing} colors={colors}>
            <item.icon />
          </IconWrapper>
          <TextWrapper>
            <Subheadline as="h3" text={item.title} size="two" />
          </TextWrapper>
        </WorkshopItem>
      ))}
    </WorkshopsContainer>
  );
};

export default Workshops;
