import { useRef } from 'react';

import LatestPostsV2 from 'components/Moleculars/LatesPostsV2';
import Newsletter from 'components/Moleculars/Newsletter';
// import Newsletter from 'components/Moleculars/Newsletter';
import Title from 'components/Moleculars/Title';

import AboutMe from './components/AboutMe';
import Banner from './components/Banner';
import Brands from './components/Brands';
import HeroSection from './components/HeroSection';
import InfoBanner from './components/InfoBanner';
import Offers from './components/Offers';
import Testimonials from './components/Testimonials';
import {
  SUBTITLE_BLOG,
  SUBTITLE_BRANDS,
  SUBTITLE_NEWSLETTER,
  SUBTITLE_TESTIMONIALS,
  SUBTITLE_WHAT_I_DO,
  TITLE_BLOG,
  TITLE_BRANDS,
  TITLE_NEWSLETTER,
  TITLE_TESTIMONIALS,
  TITLE_WHAT_I_DO,
} from './HomePage.constants';

const HomePage = () => {
  const offersRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <HeroSection
        scrollToOffers={() =>
          offersRef.current &&
          offersRef.current.scrollIntoView({ behavior: 'smooth' })
        }
      />
      <Title title={TITLE_WHAT_I_DO} subtitle={SUBTITLE_WHAT_I_DO} />
      <div ref={offersRef}>
        <Offers />
      </div>
      <Title title={TITLE_BRANDS} subtitle={SUBTITLE_BRANDS} />
      <Brands />
      <AboutMe />
      <Title title={TITLE_TESTIMONIALS} subtitle={SUBTITLE_TESTIMONIALS} />
      <Testimonials />
      <Title title={TITLE_BLOG} subtitle={SUBTITLE_BLOG} />
      <LatestPostsV2 />
      <Banner />
      <Title title={TITLE_NEWSLETTER} subtitle={SUBTITLE_NEWSLETTER} />
      <Newsletter />
      <InfoBanner />
    </>
  );
};

export default HomePage;
