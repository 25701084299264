import { useState } from 'react';
import { Link } from 'react-router-dom';

import LogoIcon from 'assets/icons/logo.svg?react';

import useNavigationStore from '../../hooks/useNavigationStore';
import MobileMenu from './assets/mobileMenu.svg?react';
import { MENU_ITEMS } from './Navigation.constants';
import styles from './Navigation.module.css';

const Navigation = () => {
  const [activeItem, setActiveItem] = useState('');
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const { setActiveUrl, isActiveMobileMenu, setIsActiveMobileMenu } =
    useNavigationStore();

  const handleMenuItemClick = (name: string) => {
    if (name === 'Usługi') {
      setIsSubmenuOpen(!isSubmenuOpen);
    } else {
      setIsSubmenuOpen(false);
    }
    setActiveItem(name);

    if (window.innerWidth <= 768) {
      if (name === 'Usługi') {
        return;
      }
      setIsActiveMobileMenu(false);
    }
  };

  const handleHamburgerMenuClick = () => {
    setIsActiveMobileMenu(!isActiveMobileMenu);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      setActiveUrl('/');
    }
  };

  return (
    <nav
      className={`${styles.navbar} ${isActiveMobileMenu ? styles.navbarActive : ''} `}
    >
      <div
        className={styles.logo}
        onClick={() => setActiveUrl('/')}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
        aria-pressed="false"
      >
        <Link to="/">
          <LogoIcon title="logo" />
        </Link>
      </div>
      <ul
        className={`${styles.menu} ${isActiveMobileMenu ? styles.menuActive : ''} `}
      >
        {MENU_ITEMS.map((item, index) => {
          return (
            <li
              key={index}
              className={`${styles.mainMenuElem} ${isSubmenuOpen && item.url === '/o-nas' ? styles.marginTop : ''}`}
            >
              {item.submenu ? (
                <>
                  <button
                    onClick={() => {
                      handleMenuItemClick(item.name);
                    }}
                    className={`${activeItem === item.name ? 'active' : ''} ${styles.submenuButton}`}
                  >
                    {item.name}
                  </button>
                  <ul
                    className={`${styles.submenuUl} ${isSubmenuOpen ? styles.isSubMenuOpen : ''}`}
                  >
                    {item.submenu.map((subItem, subIndex) => (
                      <li key={subIndex}>
                        <Link
                          to={subItem.url}
                          onClick={() => handleMenuItemClick(subItem.name)}
                          className={`${styles.menuItemLink} ${activeItem === subItem.name ? styles.menuItemLinkActive : ''}`}
                        >
                          {subItem.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <Link
                  to={item.url}
                  onClick={() => handleMenuItemClick(item.name)}
                  className={`${styles.menuItemLink} ${activeItem === item.name ? styles.menuItemLinkActive : ''} ${item.url === '/kontakt' ? styles.menuItemLinkMobileContact : ''}`}
                >
                  {item.name}
                </Link>
              )}
            </li>
          );
        })}
      </ul>
      <div
        className={`${styles.contact} ${location.pathname === '/kontakt' ? styles.contactActive : ''}`}
      >
        <Link to={'/kontakt'} onClick={() => handleMenuItemClick('Kontakt')}>
          Kontakt
        </Link>
      </div>
      <div
        onClick={handleHamburgerMenuClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
        aria-pressed="false"
        className={`${styles.hamburgerMenu} ${isActiveMobileMenu ? styles.hamburgerMenuActive : ''} `}
      >
        <MobileMenu />
      </div>
    </nav>
  );
};

export default Navigation;
